import { Box } from '@mui/material';
import DynamicFileCollector from '../../../components/Automata/UploadStep/DynamicFileCollector';
import UploadStepDataCollector from '../../../components/Automata/UploadStep/UploadStepDataCollector';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import LexTypography from '../../../components/Lex/LexTypography';

export const uploadStep = {
    stepMenuOptions: ['primaryOptions','stepMetaData','testDataDisplay'],
    initializedStep:'primaryOptions',
    execution: (props)=>
        <Box sx={{padding:2,border:1, borderRadius:3, borderColor:'action.disabled',marginTop:3}}>
        <LexTypography sx={{paddingBottom:2}} variant="h6">Upload Files</LexTypography>
        <DynamicFileCollector fixHeight={"500px"} {...props} />
        </Box>,
    executionSingleStepText: '',
    continueCondition: ({stepData,testState}) => true,
    overview: {
        name: 'uploadStep',
        label: 'Allow the user to upload documents?',
        sublabel: 'Documents may be used to provide further context and refine generation.',
        icon: <UploadFileOutlinedIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'uploadStep',
                labelDisplay: "Upload Data",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    testDataDisplay: {
        useExpandableDescription: true,
        label: 'Test Configuration',
        enabled: true,
        header: 'Test Data Configuration',
        description: 'Please input some sample inputs to test the efficacy of your flow.',
        collectorComponent: (props) => <DynamicFileCollector {...props} />

    },
    primaryOptions: {
        label: 'Step Configuration',
        useExpandableDescription: true,
        header: 'Upload Step',
        description: 'You may allow a user to upload a single file here.',
        collectorComponent: (props) => <UploadStepDataCollector {...props} />,
        buttonOpts: [],
        inputOpts: [],
    },
    default: {

    }


};