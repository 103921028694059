import React, { useContext, useEffect, useState } from "react";
import DesContext from "../../../../../contexts/singleFlow/context";
import SidebarInternalWrapper from "../SidebarInternalWrapper";
import LexTypography from "../../../../Lex/LexTypography";
import { TextField, Button, IconButton, Grid, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const Macros = ({ stepId }) => {
    const desCtx = useContext(DesContext);
    const [macros, setMacros] = useState(desCtx.manager.remoteMacros);


    const updateMacro = (index, name, val) => {
        setMacros(prevMacros => {
            let newMacros = [...prevMacros]
            newMacros[index] = { name: name, value: val }
            return newMacros
        })
    }
    const addMacro = () => {
        setMacros([...macros, { name: "", value: "" }])
    }
    const deleteMacro = (index) => {
        setMacros(prevMacros => prevMacros.filter((_, i) => i !== index));
    };

    const updateMacrosRemote = desCtx.manager.updateMacrosRemote;
    const getMacrosRemote = desCtx.manager.getMacrosRemote;


    useEffect(() => {
        getMacrosRemote(setMacros)
    }, []);

    // Debounce updateMacrosRemote calls
    useEffect(() => {
        const handler = setTimeout(() => {
            updateMacrosRemote(macros);
        }, 500); // Adjust the delay as needed

        return () => {
            clearTimeout(handler);
        };
    }, [macros]);

    return (
        <SidebarInternalWrapper>
            <Box style={{ paddingBottom: "30px" }}>
                <LexTypography variant="h5"
                    sx={{ paddingBottom: 1 }}>
                    Macros
                </LexTypography>
                <LexTypography sx={{ paddingTop: 2 }} variant="body1">
                    You may set globally defined variables here. These work like macros and may be used in any of your automations.
                </LexTypography>
                <Button variant="contained" color="primary" onClick={addMacro} style={{ marginTop: "15px" }}>
                    Add Macro
                </Button>
            </Box>
            {macros.map((macro, index) => (
                <Grid
                    container
                    spacing={2}
                    key={index + "asde"}
                    alignItems="center"
                    style={{ marginBottom: "15px" }}>
                    <Grid item xs={12}>
                        <Box
                            display='flex'
                            sx={{
                                backgroundColor: 'background.paper',
                                backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))',
                                padding: 2,
                                flexDirection: 'column'
                            }}>

                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}>
                                <TextField
                                    label="Variable Name"
                                    variant="filled"
                                    value={macro.name}
                                    onChange={(e) => updateMacro(index, e.target.value, macro.value)}
                                    style={{ width: "170px" }} />

                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-end',
                                }}>
                                    <IconButton onClick={() => deleteMacro(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </Box>

                            <TextField
                                label="Variable Value"
                                variant="filled"
                                rows={4}
                                multiline
                                value={macro.value}
                                onChange={(e) => updateMacro(index, macro.name, e.target.value)}
                                style={{ paddingTop: "10px" }}
                            />
                        </Box>
                    </Grid>
                    {/* <Grid item xs={2}>
                        <IconButton onClick={() => deleteMacro(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid> */}
                </Grid>
            ))}

        </SidebarInternalWrapper>
    )
}

export default Macros;