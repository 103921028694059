import React, { useContext } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import DesContext from '../../../../contexts/singleFlow/context.js';
import FastForwardControl from '../AutomataProcessDesign/FastForwardControl/index.js';
import StepDivider from '../AutomataProcessDesign/StepDivider/index.js';
import StylizedSummary from '../AutomataProcessDesign/StylizedSummary/index.js';
import { Box } from '@mui/material';
import Step from '../AutomataStep/index.js';

const AutomataStepWrapper = ({step,index}) => {
    const { manager } = useContext(DesContext);
    return (
        <Box sx={{width: "100%"}}>
            <Box display='flex' sx={{width: "100%"}}>
                <Box sx={{border: 1, borderColor: "action.disabled", boxShadow: 4, width: "100%",flexGrow: 1}}>
                    <Accordion key={index + " " + step} style={{ width: "100%" }}>
                        <StylizedSummary index={index} step={step} />
                        <AccordionDetails sx={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0,width: "100%" }} >
                            <Step
                                step={step}
                                stepId={step}
                                stepIndex={index}
                                stepData={{ ...manager.stepData[step] }} />
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Box>
                    <FastForwardControl index={index} />
                </Box>
            </Box>
            <StepDivider index={index} />
        </Box>

    );
};

export default AutomataStepWrapper;