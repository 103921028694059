import React, { useState, useEffect, useContext } from 'react';
import Modal from '@mui/material/Modal';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import { useTheme, Box, Button, Tab, Tabs, MenuItem, Select } from '@mui/material';
import { Logo } from '../../logoComponent';
import PolicyContext from '../../../contexts/policy/context';
import useMediaQuery from '@mui/material/useMediaQuery';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TermsOfServiceModal = ({ open }) => {
    const theme = useTheme();
    const { showPolicy, setShowPolicy } = useContext(PolicyContext);
    const [value, setValue] = useState(0);
    const [termsOfService, setTermsOfService] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [cookiePolicy, setCookiePolicy] = useState('');
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        axios.get('/tos.md')
            .then(response => {
                setTermsOfService(response.data)
            })
            .catch(error => console.error(error));
        axios.get('/cookie.md')
            .then(response => setCookiePolicy(response.data))
            .catch(error => console.error(error));
        axios.get('/privacy.md')
            .then(response => setPrivacyPolicy(response.data))
            .catch(error => console.error(error));
    }, []);
    return (
        <Modal
            open={showPolicy}
            onClose={() => setShowPolicy(false)}
            aria-labelledby="terms-of-service-modal-title"
            aria-describedby="terms-of-service-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 700,
                maxHeight: 700,
                width: '102%',
                height: '102%',
                overflowY: 'scroll',
                bgcolor: 'background.paper',
                // marginTop:"20px",
                // border: '2px solid #000',
                boxShadow: 24,
                // p: 4
            }}>
                <div style={{
                    position: 'sticky', top: '0',
                    display: "flex", flexDirection: "column", padding: "0px", width: "100%", maxWidth: "600px"
                }}>
                    <div>
                        <div style={{
                            display: 'flex', backgroundColor: theme.palette.background.paper,
                        }}>
                            <div style={{
                                paddingTop: "10px", paddingLeft: '10px'
                            }}>
                                <Logo color="#e6e6e6" format="policy" />
                            </div>
                            <Box sx={{ color: 'primary.children' }} style={{ marginLeft: "10px" }}>
                                {isMobile
                                    ? <Select
                                        value={value}
                                        variant={'filled'}
                                        onChange={(e) => setValue(parseInt(e.target.value))}
                                        style={{ color: "white", width: "150px", marginTop: isMobile ? "-15px" : null }}>
                                        <MenuItem key={0} value={0}>Terms of Service</MenuItem>
                                        <MenuItem key={1} value={1}>Privacy</MenuItem>
                                        <MenuItem key={2} value={2}>Cookies</MenuItem>
                                    </Select>
                                    : <Tabs
                                        textColor="inherit"
                                        value={value}
                                        onChange={handleChange}
                                        sx={{
                                            height: 'calc(100% + 3px)',
                                            '& div': {
                                                height: '100%',
                                            },
                                            '& .MuiTab-root': {
                                                height: '100%',
                                                padding: '0px 16px',
                                                paddingTop: '10px'
                                            },
                                            '& .Mui-selected': {
                                                backgroundColor: theme.palette.backgroundSecondary.paper, // "#2f3636",
                                                color: theme.palette.backgroundSecondary.contrastText,
                                                // clipPath: "polygon(0 0, 100%  0, calc(100% - 15px) 100%, 0px 100%)",
                                            },
                                        }}>
                                        <Tab label="Terms of Service" {...a11yProps(0)} />
                                        <Tab label="Privacy"  {...a11yProps(1)} />
                                        <Tab label="Cookies"   {...a11yProps(2)} />
                                        {/* <Tab icon={<SettingsIcon />}{...a11yProps(3)} /> */}
                                    </Tabs>}

                            </Box>
                        </div>
                        {isMobile ? <Button
                            style={{ marginLeft: "0px", marginTop: "10px" }}
                            onClick={() => setShowPolicy(false)}
                            variant="outlined"
                            color="primary"
                            fullWidth
                        >
                            Close
                        </Button> : null}
                    </div>
                </div>
                {/* <div style={{ display: 'flex', padding: "20px", flexDirection: 'column', backgroundColor: theme.palette.background.default, height: '80vh', maxWidth: "1000px", overflowY: "scroll" }}> */}
                <Box sx={{ p: 4 }}>
                    <ReactMarkdown components={{
                        h1({ node, inline, className, children, ...props }) {
                            return (<h1 {...props} style={{ marginBlockStart: "0", fontSize: "2.2em", marginBlockEnd: "0", }}>
                                {children}
                            </h1>)
                        },
                        h2({ node, inline, className, children, ...props }) {
                            return (<h2 {...props} style={{ marginBlockStart: "3px", paddingTop: "10px", paddingBottom: "10px", marginBlockEnd: "0", }}>
                                {children}
                            </h2>)
                        },
                        h3({ node, inline, className, children, ...props }) {
                            return (<h3 {...props} style={{ marginBlockStart: "3px", paddingTop: "10px", paddingBottom: "10px", fontSize: "1.2em", marginBlockEnd: "0", }}>
                                {children}
                            </h3>)
                        },
                        h4({ node, inline, className, children, ...props }) {
                            return (<h4 {...props} style={{ marginBlockStart: "3px", marginBlockEnd: "0", }}>
                                {children}
                            </h4>)
                        },
                        strong({ node, inline, className, children, ...props }) {
                            return (<strong {...props} style={{ marginBlockStart: "3px", paddingTop: "4px", paddingBottom: "4px", marginBlockEnd: "0", }}>
                                {children}
                            </strong>)
                        },
                        a({ node, inline, className, children, ...props }) {
                            return (<a {...props} style={{ marginBlockStart: "3px", marginBlockEnd: "0", color: "green" }}>
                                {children}
                            </a>)
                        },
                    }}>
                        {value === 0 ? termsOfService : (value === 1 ? privacyPolicy : cookiePolicy)}
                    </ReactMarkdown>
                </Box>
                {/* </div> */}
            </Box>
        </Modal >
    );
};

export default TermsOfServiceModal;