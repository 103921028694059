import { useState } from 'react';
import { generateOutputs } from '../utilities';

export const useModalOutputEditorSingle = (configureStep,testState,stepId,varName) => {
    const outputs = generateOutputs(testState, stepId);
    const [selectedOutput, setSelectedOutput] = useState(outputs[0]?.key);
    const [editModalData, setEditModalData] = useState({ enabled: false });
    const submitModalEdits = (newText) => {
        configureStep.setTest({
            [editModalData.varName]: newText,
        })
        setTimeout(() => {
            setEditModalData({ enabled: false });
        }, 50);
    }
    const closeModal = () => {
        setEditModalData({ enabled: false });
    }
    const editText = (varName, stepId) => {
        setEditModalData({ 
            enabled: true, 
            varName: varName, 
            stepId: stepId, 
            varText: testState?.[varName] ? testState?.[varName] : "" });
    }
    const handleSelectOutput = (key) => {
        setSelectedOutput(key);
    };
    return {
        submitModalEdits,
        editText,
        handleSelectOutput,
        selectedOutput,
        editModalData,
        outputs,
        closeModal
    }

}