
import React from 'react';
import { Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew'; // Import the icon
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const OutputTable = ({ selectedOptions, metadataKeys, possibleOptions, utilizedOptions, handleCheckboxChange, openExternalFile }) => {

    const formatHeader = (header) => {
        return header
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Use file?</TableCell> {/* Checkbox column header */}
                        <TableCell>Active</TableCell> {/* Checkbox column header */}
                        {metadataKeys.map((key) => {
                            return <TableCell key={key}>{formatHeader(key)}</TableCell>
                        })}
                        <TableCell key={'view_file_output_table'}>View</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {possibleOptions.map((option) => (
                        <TableRow key={option['name']}>
                            <TableCell>
                                <Checkbox
                                    checked={selectedOptions.includes(option['name'])}
                                    onChange={(event) => handleCheckboxChange(event, option['name'])}
                                    name={option['name']}
                                />
                            </TableCell>

                            <TableCell>
                                {utilizedOptions.includes(option['name']) ? <IconButton sx={{ color: 'primary.tab' }} onClick={() => { }} aria-label="check">
                                    <CheckCircleIcon />
                                </IconButton> : ''}
                            </TableCell>
                            {metadataKeys.map((key) => (
                                <TableCell key={key}>{option[key].substring(0, 40)}</TableCell>
                            ))}
                            <TableCell>
                                {option['dd_link'] && (
                                    <IconButton onClick={() => openExternalFile(option)} aria-label="open">
                                        <OpenInNewIcon />
                                    </IconButton>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default OutputTable;