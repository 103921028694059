import React, { useState, useContext } from 'react';
import Modal from '@mui/material/Modal';
import { useTheme, Box, Button, Grid, TextField, Typography } from '@mui/material';
import ContactContext from '../../../contexts/contact/context';
import useMediaQuery from '@mui/material/useMediaQuery';
import APIContext from '../../../contexts/api/context';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ContactModal = ({ open }) => {
    const { api } = useContext(APIContext)
    const theme = useTheme();
    const { showModal, setShowModal } = useContext(ContactContext);
    // const { firstName,lastName,email,phone,lawfirmWebsite,title,numOfLawyers } = req.body;
    const [data, setData] = useState({});
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Modal
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="contact-us-modal-title"
            aria-describedby="contact-us-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: 700,
                maxHeight: 700,
                width: '102%',
                height: '102%',
                overflowY: 'scroll',
                bgcolor: 'background.paper',
                borderRadius: !isMobile ? "25px" : null,
                // marginTop:"20px",
                // border: '2px solid #000',
                // p: 4
            }}>
                <div style={{ padding: "30px" }}>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Contact Us
                    </Typography>
                    Tell us about your firm or company and our team will reach out to set up a call.
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            {[["First Name", "firstName"],
                            ["Email", "email"],
                            ["Law Firm Website", "lawfirmWebsite"],
                            ["Number of Lawyers at Firm", "numOfLawyers"],


                            ].map(([label, id]) => {
                                return <TextField
                                    key={label + id + "g1"}
                                    id={id}
                                    value={data?.[id]}
                                    onChange={(e) => setData({ ...data, [id]: e.target.value })}
                                    label={label}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            })}
                        </Grid>
                        <Grid item xs={6}>
                            {[["Last Name", "lastName"],
                            ["Phone", "phonel"],
                            ["Title", "title"],
                            ].map(([label, id]) => {
                                return <TextField
                                    key={label + id + "g2"}
                                    id={id}
                                    value={data?.[id]}
                                    onChange={(e) => setData({ ...data, [id]: e.target.value })}
                                    label={label}
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            })}
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained" color="primary"
                        onClick={() => {
                            api('/ptntst/salesInquiry', data
                            ).then(res => { })

                        }} sx={{ mt: 2 }}>
                        Submit
                    </Button>
                    <br />
                    <Button variant="contained" color="primary" style={{ width: "100px" }} onClick={() => { setShowModal(false) }} sx={{ mt: 2 }}>
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ContactModal;