import React, { useState } from 'react';
import 'reactflow/dist/style.css';
import {
    Button
} from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const ConfirmationModal = ({confirmationTitle, handleClose, confirmationText, open, onConfirm}) => {
    return (
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{confirmationTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button  onClick={handleClose}>Cancel</Button>
                    <Button onClick={() => onConfirm()} autoFocus>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
    );
};

export default ConfirmationModal;