import { useEffect, useState } from 'react';
import useHiddenState from './useHiddenState';


const useArrayCheckboxHiddenState = (testState,configureStep,stepId,checkboxOptionsKey,selectedOptionsKey) => {
    const {retrieveHiddenState,embedHiddenState} = useHiddenState(testState,configureStep,stepId,2)
    const possibleOptions = retrieveHiddenState(checkboxOptionsKey,[]);
    const [selectedOptions, setSelectedOptions] = useState(retrieveHiddenState(selectedOptionsKey,[])); 

    useEffect(() => {
        const filteredOptions = selectedOptions.filter(option =>
            possibleOptions.some(possibleOption => possibleOption.name === option)
        );
        const setsAreDifferent = new Set(filteredOptions).size !== new Set(selectedOptions).size ||
            filteredOptions.some(option => !selectedOptions.includes(option));

        if (setsAreDifferent) {
            setSelectedOptions(filteredOptions);
        }
    }, [possibleOptions]);

    useEffect(() => {
        embedHiddenState(selectedOptionsKey,selectedOptions)
    }, [selectedOptions])

    const handleCheckboxChange = (event, optionName) => {
        if (event.target.checked) {
            setSelectedOptions([...selectedOptions, optionName]);
        } else {
            setSelectedOptions(selectedOptions.filter((item) => item !== optionName));
        }
    };

    return {selectedOptions, handleCheckboxChange, possibleOptions}
}

export default useArrayCheckboxHiddenState;