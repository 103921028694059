import { Box } from "@mui/material"

export const PolygonLabel = ({
    className,
    children,
    bgColor,
    height,
    width,
    style,
    sx,
    clip = "polygon(0 0, calc(100% - 15px)  0, 100% 100%, 0 100%)"
}) => {

    /* How we transfer visual "feel" from the polygon clip-path headers at
     * frontend/web/src/components/Automata/AutomataProcessDesign/StylizedSummary/index.js
     * 
     * Visually, static horizontal negative space is best approximated by a constant function, so we just transfer the left padding over directly.
     * 
     * In the original headers, horizontal negative space on the right changed with respect to the width of the content.
     * To approximate this, we need a way to calculate the _width_ that accounts for the already constant used-up left space.
     * 
     * Regarding inner width, the left space is simply padding. There was a very small difference between largest and smallest content lengths in the 
     * original left headers, and the same remains true of these new labels. So, we use a function of the largest content length.
     * This will best approximate the original visual effect.
     * 
     * And since left space is a constant, this function acts on the remaining space after removing the original left padding.
     * Afterwards, we simply add the padding back to the new inner width.
     * 
     * inner width ratio = {{old inner width} - {inner left padding}} / {old inner largest content length}
     * new outer width = {{inner width ratio} * {new inner largest content length}} + {inner left padding}
     * 
     * Since there was much more deviation between the largest & smallest content lengths in the right headers and this remains true of the new labels,
     * we use a function of the smallest content length. This has a similar effect to the above function. The constant padding here changes, though,
     * since the rightmost content length has different left padding and since we have the inner width on the left. So we keep the outer left padding
     * constant, but we remove the old inner width when finding the ratio, then add back the new inner width when finding the new outer width.
     * 
     * outer width ratio = {{old outer width} - {old inner width} - {outer padding left}} / {old outer smallest content length}
     * new outer width = {{outer width ratio} * {new outer smallest content length}} + {outer padding left} + {new inner width} 
     * 
     * -J */

    return (
        <Box className={className}
            sx={sx}
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: height,
                width: width,
                backgroundColor: bgColor,
                clipPath: clip,

                ...style
            }}>
            {children}
        </Box>
    )
}

