import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const UploadStepDataCollector = ({ configureStep, stepData, stepId,errorDisplay }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.")
        ]}
    />
};

export default UploadStepDataCollector;