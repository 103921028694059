import React, { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context.js';
import AutomataFormInput from '../../AutomataPrimary/AutomataFormInput/index.js';
import { Box } from '@mui/material';

const FormDataExecution = ({ stepData }) => {
    const { manager } = useContext(DesContext);
    const collectedFields = stepData?.collectedFields ? stepData?.collectedFields : [];
    return (<>
        {collectedFields.map((field) => {
            return (
                <Box
                    style={{
                        margin: '5px 0px 20px',
                        padding: "20px",
                        width: '550px',
                        borderRadius: '20px',
                    }}>
                    <AutomataFormInput
                        stepId={manager.previewModeActiveStep}
                        activeStepData={stepData}
                        field={field} />
                </Box>)
        })}
    </>)

};

export default FormDataExecution;