import React from 'react';
import { stepOptionsArray } from '../../../../automata/step/steps';
import { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context';
import LexTypography from '../../../Lex/LexTypography';
import LexLargeButtonList from '../../../Lex/LexLargeButtonList';
import { Box } from '@mui/material';

const AutomataStepCreation = ({ stepData, stepIndex, stepId }) => {
    const { manager } = useContext(DesContext);
    const stepOperations = manager.createStepOperations({ stepData, stepIndex, stepId });
    const options = stepOptionsArray
    const clickHandler = (option) => { option.overview.click(stepOperations)}
    return (
        <Box>
            <LexTypography variant="h6">What kind of step is this?</LexTypography>
            <LexTypography variant="body1">Do you need to...</LexTypography>
            <LexLargeButtonList options={options} clickHandler={clickHandler} />
        </Box>
    );
};

export default AutomataStepCreation;