import React, { useContext } from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DesContext from '../../../../../contexts/singleFlow/context';
import { Box, useTheme } from '@mui/material';
import useIsSmallContainer from '../../../Common/hooks/useIsSmallContainer';

//#TODO:CSS-CLEAN-UP, STYLE-REWORK
const StylizedSummary = ({ step, index }) => {
  const theme = useTheme();
  const { manager } = useContext(DesContext);
  const { isSmallContainer, containerRef } = useIsSmallContainer();

  // Large Screen Layout
  const LargeScreenLayout = () => (
    <Box
      sx={{
        bgcolor: manager.stepData[step]?.suppliedName ? "flags.right" : null,
        color: "flags.rightText"
      }}
      style={{
        display: "flex",
        height: "60px",
        width: "550px",
        clipPath: "polygon(0 0, 535px  0, 100% 100%, 0 100%)"
      }}><Box

        sx={{
          bgcolor: manager.stepData[step].labelDisplay ? 'flags.middle' : null,
          color: "flags.middleText"
        }}
        style={{
          display: "flex",
          height: "60px",
          width: "300px",
          clipPath: "polygon(0 0, 285px  0, 100% 100%, 0 100%)"
        }}>
        <Box
          sx={{
            bgcolor: 'flags.left',
            color: "flags.leftText"
          }}
          style={{
            paddingLeft: "12px",
            paddingTop: "14px", height: "60px",
            width: "120px",
            clipPath: "polygon(0 0, 105px  0, 100% 100%, 0 100%)"

          }}>
          <Typography variant="h6">Step {index + 1}</Typography>
        </Box>
        <Box style={{ paddingLeft: "20px", paddingTop: "14px", color: theme.palette.backgroundSecondary.contrastText, }}>
          <Typography variant="h6">{manager.stepData[step].labelDisplay}</Typography>
        </Box>
      </Box>
      <Box style={{
        paddingLeft: "15px",
        paddingTop: "18px",
        overflow: "hidden",
        maxWidth: "200px",
      }}>
        <Typography
          sx={{ fontWeight: "500" }}
          style={{
            fontWeight: '500',
            width: '100%',
            overflow: 'hidden',
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: '1em',
          }}>{manager.stepData[step].suppliedName}</Typography>
      </Box>
    </Box>
  );

  // Small Screen Layout
  const SmallScreenLayout = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        //   backgroundColor: manager.stepData[step]?.suppliedName ? "white" : null,
        width: "100%",
        boxSizing: "border-box",
        clipPath: "none",
      }}
    >
      <Box
        sx={{
          bgcolor: 'flags.left',
          color: "flags.leftText"
        }}
        style={{
          padding: "10px",
          width: "calc(60% - 20px)",
          clipPath: "polygon(0 0, calc(100% - 10px)  0, 100% 100%, 0 100%)",
        }}
      >
        <Typography variant="h6">Step {index + 1}</Typography>
      </Box>
      <Box
        sx={{
          bgcolor: manager.stepData[step].labelDisplay ? 'flags.middle' : null,
          color: "flags.middleText"
        }}
        style={{
          padding: "10px",
          width: "calc(60% - 10px)",
          clipPath: "polygon(0 0, calc(100% - 10px)  0, 100% 100%, 0 100%)",
        }}
      >
        <Typography variant="h6">
          {manager.stepData[step].labelDisplay}
        </Typography>
      </Box>

      <Box
        sx={{
          bgcolor: manager.stepData[step]?.suppliedName ? "flags.right" : null,
          color: "flags.rightText"
        }}
        style={{
          padding: "10px",
          width: "calc(60%)",
          clipPath: "polygon(0 0, calc(100% - 10px)  0, 100% 100%, 0 100%)",
        }}
      >
        <Typography
          sx={{ fontWeight: "500" }}
          style={{
            fontWeight: '500',
            width: '100%',
            overflow: 'hidden',
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            paddingRight: '1em',
          }}>{manager.stepData[step].suppliedName ? manager.stepData[step].suppliedName : "(Unnamed)"}</Typography>
      </Box>
    </div>
  );

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls={`panel${index}a-content`}
      id={`panel${index}a-header`}
      style={{ backgroundColor: "rgb(0,0,0,.15)", width: "100%" }}
    >
      <div style={{ width: "100%" }}>
        <div ref={containerRef}>
          {isSmallContainer ? <SmallScreenLayout /> : <LargeScreenLayout />}
        </div>
      </div>
    </AccordionSummary>
  );
};


export default StylizedSummary;