import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import {
    useNodesState,
    useEdgesState,
    addEdge,
    MarkerType,
} from 'reactflow';
import html2canvas from 'html2canvas';
import NodeEdgeContext from './context';
import 'reactflow/dist/style.css';
import ClearContext from '../figureDesignClearContext/context';

function downloadReactFlowScreenshot(elementId) {
    const element = document.getElementById(elementId); // Get the react-flow element by its ID

    if (element) {
        html2canvas(element).then((canvas) => {
            // Create an image from the canvas
            const image = canvas.toDataURL('image/png');
            // Create a link to download the image
            const link = document.createElement('a');
            link.href = image;
            link.download = 'react-flow-screenshot.png'; // Name the download file
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up
        });
    } else {
        console.error('Element not found:', elementId);
    }
}

const initialNodes = [
    {
        id: '1',
        type: 'figureNode',
        position: { x: 100, y: 100 },
        data: { label: 'Sample Node 1', top: true, right: true, bottom: true, left: true },
        style: {
            background: '#0000',
            backgroundColor: '#0000',
        },
    },
    {
        id: '2', type: 'figureNode',
        position: { x: 100, y: 200 },
        data: { label: 'Sample Node 2', top: true, right: true, bottom: true, left: true },
        style: {
            background: '#0000',
            backgroundColor: '#0000',
        },
    },
];
const initialEdges = [];

const NodeEdgeProvider = ({ children }) => {
    const { clearVer } = useContext(ClearContext);

    const [figuresActive, setActiveFigures] = useState(null);
    const [screenshotMode, setScreenshotMode] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [selectedEdge, setSelectedEdge] = useState(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [ver, setVer] = useState(0);
    const [resizing, setResizing] = useState(false)
    const [value, setValue] = useState(0);
    const [toggleables, setToggleables] = useState(() => [/* 'resize', 'locked' */]);
    const [connections, setConnections] = useState([]);
    const displayRef = useRef();
    const [landscape, setLandscape] = useState(() => { })
    const nodesRef = useRef(nodes);
    const [figureId, setFigureId] = useState("");
    nodesRef.current = nodes;
    const recomputeGroups = () => {
        // Iterate over all nodes
        // console.log(nodes)
        const newNodes = nodesRef.current.map((node) => {
            // Check if the node has a groupId
            const newNode = { ...node }
            if (node?.data?.groupId) {
                // Find the group node
                let groupNode = nodes.find(n => n.id === node.data.groupId);
                // Check if the group node exists and has groupNode set to true
                if (!(groupNode && groupNode.data && groupNode.data.groupNode)) {
                    // If not, set the groupId to the empty string
                    newNode.data.groupId = '';
                }
            }
            return newNode
        })
        setNodes(newNodes)
        setVer(v => v + 1)
    }

    useEffect(() => {
        // setActiveFigures(null)
        setFigureId("")
        setScreenshotMode(false)
        setSelectedNode(null)
        setSelectedEdge(null)
        setNodes(initialNodes)
        setEdges(initialEdges)
        setVer(0)
        setResizing(false)
        setValue(0)
        setToggleables(() => [/* 'resize', 'locked' */])
        setConnections([])
        setLandscape(() => { })

    }, [clearVer])

    function deleteSelectedNode() {
        const newNodes = nodes.filter(node => node.id !== selectedNode);
        const newEdges = edges.filter(edge => edge.source !== selectedNode && edge.target !== selectedNode);
        setEdges(newEdges)
        setNodes(newNodes)
        setSelectedNode(null)
        setTimeout(recomputeGroups, 100)
        setVer(v => v + 1)
    }

    const modifyNodeData = (id, data) => {
        setNodes((oldNodes) => {
            const node = oldNodes.find((n) => n.id === id);
            const newNodes = [...oldNodes];
            const nodeIndex = oldNodes.indexOf(node);
            newNodes[nodeIndex] = {
                ...node,
                style: {
                    ...node.style,
                    background: '#0000',
                    backgroundColor: '#0000',
                },
                data: {
                    ...node.data,
                    ...data,
                },
            };
            return newNodes;
        });
        setVer((ver) => ver + 1);
    }

    // draggable: !newToggle.includes('lock'),
    const lockNode = (id, locked) => {
        setNodes((oldNodes) => {
            const node = oldNodes.find((n) => n.id === id);
            const newNodes = [...oldNodes];
            const nodeIndex = oldNodes.indexOf(node);

            console.log('nodeIndex', nodeIndex, 'node', node, 'locked', locked, 'oldNodes', oldNodes, 'newNodes', newNodes)

            newNodes[nodeIndex] = {
                ...node,
                data: { ...node.data }
            }
            delete newNodes[nodeIndex].draggable
            delete newNodes[nodeIndex].zIndex
            if (locked) {
                newNodes[nodeIndex].draggable = false
                newNodes[nodeIndex].zIndex = -1
            }

            return newNodes;
        });
        setVer((ver) => ver + 1);
    }

    const handleToggle = (e, newToggleables) => {
        setToggleables(newToggleables);
        if (newToggleables.includes('resize')) setResizing(true)
        else setResizing(false)

        if (newToggleables.includes('locked'))
            lockNode(selectedNode, true)
        else
            lockNode(selectedNode, false)
    };

    useEffect(() => {
        if (selectedNodeFull) {
            let newToggleables = []
            if (selectedNodeFull?.data?.resizable) newToggleables.push('resize')
            if (selectedNodeFull?.data?.locked) newToggleables.push('locked')
            setToggleables(newToggleables)
        }
    }, [selectedNode])

    const deleteNodeAndEdgesById = (nodeId) => {

        setTimeout(() => {
            setSelectedNode(null);
            setSelectedEdge(null);
            const newNodes = nodes.filter((node) => node.id !== nodeId);
            const newEdges = edges.filter((edge) => edge.source !== nodeId && edge.target !== nodeId);
            setNodes(newNodes);
            setEdges(newEdges);
            setVer((ver) => ver + 1);
        }, 100)

    };

    const onConnect = useCallback((params) => {
        console.log(params)
        setEdges((eds) => addEdge({
            ...params,
            type: 'bezier',
            // type: 'custom'

            bothWays: false,
            directionless: false,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 15,
                height: 15,
            },
            labelStyle: {
                fontSize: 17,
            },
            style: {
                // TODO: maybe here
                fontSize: 64,
                strokeWidth: 2,
            },
        }, eds));
        setConnections((conns) => [...conns, params]);
    }, [setEdges]);
    const selectedNodeFull = nodes.find((n) => n.id === selectedNode);
    const timeout = (prom, time) => Promise.race([prom, new Promise((_r, rej) => setTimeout(rej, time))]);
    async function downloadReactFlowScreenshotFromCTX(elementId) {
        setNodes((oldNodes) => oldNodes.map((node) => {
            const newNode = { ...node }
            newNode.data.hideHandles = true
            return newNode
        }))
        setScreenshotMode(true)
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const element = document.getElementById(elementId); // Get the react-flow element by its ID

                if (element) {
                    html2canvas(element).then((canvas) => {
                        // Rotate the canvas if width is greater than height
                        let finalCanvas = canvas; // Assume no rotation is needed initially
                        if (canvas.width > canvas.height) {
                            // Create a new canvas where the rotated image will be drawn
                            finalCanvas = document.createElement('canvas');
                            finalCanvas.width = canvas.height;
                            finalCanvas.height = canvas.width;

                            const ctx = finalCanvas.getContext('2d');
                            // Rotate and draw the original canvas onto the new canvas
                            ctx.rotate(90 * Math.PI / 180); // Convert 90 degrees to radians
                            ctx.drawImage(canvas, 0, -finalCanvas.width);
                        }

                        // Create an image from the (possibly rotated) canvas
                        setNodes((oldNodes) => oldNodes.map((node) => {
                            const newNode = { ...node };
                            newNode.data.hideHandles = false;
                            return newNode;
                        }));
                        setScreenshotMode(false);

                        finalCanvas.toBlob((blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Blob creation failed'));
                            }
                        }, 'image/png');
                    });
                } else {
                    reject(new Error(`Element not found: ${elementId}`));
                }
            }, 100)
        })
    }
    const getScreenshot = () => {
        setNodes((oldNodes) => oldNodes.map((node) => {
            const newNode = { ...node }
            newNode.data.hideHandles = true
            return newNode
        }))
        setScreenshotMode(true)
        setTimeout(() => {
            downloadReactFlowScreenshot('my-react-flow')
            setTimeout(() => {
                setNodes((oldNodes) => oldNodes.map((node) => {
                    const newNode = { ...node }
                    newNode.data.hideHandles = false
                    return newNode
                }))
                setScreenshotMode(false)
            }, 100)
        }, 100)
    }
    const onNodeDrag = useCallback((event, node) => {
        // set selected node when it's dragged
        if (toggleables.length > 0) return;

        if (selectedNode !== node.id) setSelectedNode(node.id)
        const minX = 0;
        const minY = 0;
        const maxX = displayRef.current.offsetWidth - node.width; // Adjust based on your canvas size
        const maxY = displayRef.current.offsetHeight - node.height; // Adjust based on your canvas size
        const clampedPosition = {
            x: Math.min(Math.max(node.position.x, minX), maxX),
            y: Math.min(Math.max(node.position.y, minY), maxY),
        };
        setNodes((nds) =>
            nds.map((n) => {
                if (n.id === node.id) {
                    return {
                        ...n,
                        position: clampedPosition,
                    };
                }
                return n;
            })
        );
    }, [setNodes]);

    const changeEdge = (newEdges, deleted = false) => {
        if (deleted) setSelectedEdge(false)
        setEdges(newEdges)
    }

    const onEdgeClick = (event, edge) => {
        setSelectedEdge(edge.id)
        setValue(1) // Automatically bring to edge tab.
    };
    const handleAddNode = () => {
        setNodes((oldNodes) => [...oldNodes, {
            id: (Math.random() * 1000).toString(),
            type: 'figureNode', // this should match the type you've defined for your custom node
            data: {
                label: 'New node',
                top: true,
                right: true,
                bottom: true,
                left: true,
                resizable: false,
                locked: false,
            },
            style: {
                background: '#0000',
                backgroundColor: '#0000',
            },
            position: { x: 0, y: 0 },
        }]);
        setVer((ver) => ver + 1);
    };
    return (
        <NodeEdgeContext.Provider value={{
            nodes, edges,
            setNodes, setEdges,
            onNodesChange,
            onEdgesChange,
            modifyNodeData,
            handleAddNode,
            ver,
            setVer,
            resizing,
            setResizing,
            lockNode,
            onEdgeClick,
            changeEdge,
            onNodeDrag,
            getScreenshot,
            deleteNodeAndEdgesById,
            selectedNode,
            setSelectedNode,
            selectedEdge,
            setSelectedEdge,
            figureId, setFigureId,
            recomputeGroups,
            selectedNodeFull,
            displayRef,
            toggleables,
            setToggleables,
            connections,
            downloadReactFlowScreenshotFromCTX,
            setConnections,
            handleToggle,
            onConnect,
            value,
            deleteSelectedNode,
            setValue, screenshotMode,
            figuresActive,
            setActiveFigures,
            landscape,
            setLandscape
        }}>
            {children}
        </NodeEdgeContext.Provider>
    );
}

export default NodeEdgeProvider;