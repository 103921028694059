import React from 'react'
import { Alert, Container, Paper, Typography, useTheme } from "@mui/material";
import { Logo } from '../components/logoComponent';
import { useNavigate } from "react-router-dom";
import { Box } from '@mui/material';

export const buttonStyle = { width: 'calc(100% - 0.4cm)', margin: '0.3cm 0.2cm 0cm' };
export const lastButtonStyle = { width: 'calc(100% - 0.4cm)', margin: '0.3cm 0.2cm 0.4cm' };
export const SiteGate = (props) => {
    const navigate = useNavigate();
    const theme = useTheme();
    const getChildrenById = (id) => {
        const childrenWithId = props.children
            .filter(child => child?.props?.id === id);

        return childrenWithId
    }
    console.log(theme)
    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Box className="sitegate" sx={{ backgroundColor: 'bg.main' }}></Box>
            <div style={{
                position: 'absolute',
                // padding: '6em',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
            }}>
                <Container component="main" maxWidth="xs">
                    {/* <Paper */}
                    <div
                        style={{

                            alignItems: 'center',
                            borderRadius: '10px',
                            height: "fit-content",
                            minHeight: '20cm',
                            display: 'flex',
                            flexDirection: 'column',
                            // justifyContent: 'center',
                            padding: '1cm 0.5cm',

                            // padding: 2
                        }}>
                        <div style={{
                            display: 'flex', alignItems: 'center',
                            flexDirection: 'column', paddingLeft: "10px",
                            paddingRight: "10px",
                            // height: "16cm"
                            height: '18cm',
                        }}>
                            <div className='sitegate-part' onClick={() => navigate('/')}>
                                <Logo color={theme.palette.backgroundSecondary.default} setOpenPage={() => { }} />
                            </div>

                            <div style={{
                                flex: '1 1 auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                            }}>
                                <div />
                                {/* <div className='sitegate-divider' /> */}

                                <Typography
                                    component="h1"
                                    variant="h5"
                                    style={{ margin: '0.7cm 0cm' }}>
                                    {props.title}
                                </Typography>


                                {props.error &&
                                    <Alert style={{ marginBottom: "20px" }} severity="error">
                                        {props.error}</Alert>}

                                {props.children}

                                <div style={{ height: '0.5cm' }} />
                                {/* <div className='sitegate-divider' /> */}

                                {props.buttons
                                    ? props.buttons
                                    : null}

                                <div style={{
                                    height: '1cm',
                                    flex: '1 1 auto',
                                }} />

                            </div>
                        </div>
                    </div>
                    {/* </Paper> */}
                </Container>
            </div>
        </div>
    )
}

