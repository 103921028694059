import {useState,useEffect} from "react";

export const usePreviewMode=(
    activeWorkflow, 
    steps, 
    actionPackager,
    apiWithLoading, 
    updateTestProfile,
    setIsRunning)=>{

    const [previewMode, setPreviewMode] = useState(false);
    const [previewModeInit, setPreviewModenit] = useState(false);
    const [previewModeActiveStep, setPreviewModeActiveStep] = useState("");

    const executePreviewSequence = (stepId, noAdvance) => () => {
        let index = steps.indexOf(stepId)
        setIsRunning(true)
        const reqPkg = actionPackager(index)
        apiWithLoading('/ptntst/debugExecution',reqPkg ).then(res => {
            if (index + 1 < steps.length && !noAdvance) {
                setPreviewModeActiveStep(steps[index + 1])
            }
            updateTestProfile(res.data.history)
            
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    const skipForwardPreviewSequence = (stepId) => () => {
        let index = steps.indexOf(stepId)
        if (index + 1 < steps.length) {
            setPreviewModeActiveStep(steps[index + 1])
        }
    }
    
    const simpleAdvanceSeq = (stepId) => () => {
        let index = steps.indexOf(stepId)
        if (index + 1 < steps.length) {
            setPreviewModeActiveStep(steps[index + 1])
        }
    }
    useEffect(() => {
        if (previewMode) {
            setPreviewModenit(true)
        }
    },
        [previewMode])

    useEffect(() => {
        if (previewModeInit) {
            setPreviewModenit(false)
            if (steps.length > 0) {
                setPreviewModeActiveStep(steps[0])
            }
        }
    },
        [previewModeInit, steps])

    useEffect(() => {
            setPreviewMode(false)
            setPreviewModenit(false)
            setPreviewModeActiveStep("")
        }, [activeWorkflow])
    
    const previewStepIndex = steps.indexOf(previewModeActiveStep)

    return {
        previewMode, 
        setPreviewMode, 
        executePreviewSequence, 
        skipForwardPreviewSequence, 
        simpleAdvanceSeq, 
        previewModeActiveStep, 
        previewStepIndex}

}