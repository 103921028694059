import React, { useContext } from 'react';
import { stepOptionsObject } from '../../../../automata/step/steps';
import DesContext from '../../../../contexts/singleFlow/context';
import MetadataCollector from '../../Common/MetadataCollector';
import AutomataStepCreation from '../AutomataStepCreaton';
import { useDynamicMenu, AutomataStepDynamicMenu } from '../AutomataStepDynamicMenu';
import AutomataStepConfiguraton from '../AutomataStepConfiguration';
import { Box } from '@mui/material';

//#TODO:CSS-CLEAN-UP
const StepConfiguration = ({ stepData, stepIndex, stepId }) => {
    const { manager } = useContext(DesContext);
    const stepOperations = manager.createStepOperations({ stepData, stepIndex, stepId });
    const stepProfile = stepOptionsObject[stepData.name];
    const options = stepProfile[stepData.display];
    const { selectedOption, setSelectedOption, displayOptions, } = useDynamicMenu(0, stepProfile, stepData);
    const currentOptions = stepProfile?.[selectedOption.name];
    return (<div style={{ width: "100%" }}>
        {stepData.display === stepProfile.initializedStep ?
            <>
                <AutomataStepDynamicMenu
                    stepId={stepId}
                    options={displayOptions}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption} />
                <Box
                    sx={{ paddingLeft: 3, paddingRight: 3 }}
                    style={{
                        width: "calc(100%-25px)"
                    }}>
                    {stepProfile?.[selectedOption.name] ?
                        <AutomataStepConfiguraton
                            options={currentOptions}
                            legacy={false}
                            stepOperations={stepOperations}
                            collectorComponent={stepProfile[selectedOption.name]?.collectorComponent}
                        /> : null}
                    {selectedOption.name === 'stepMetaData' ? <MetadataCollector
                        stepData={stepData}
                        stepId={stepId}
                        stepIndex={stepIndex}
                    /> : null}
                </Box>
            </> :
            <Box
                sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3 }}
                style={{
                    width: "calc(100%-25px)"
                }}>
                <AutomataStepConfiguraton
                    options={options}
                    legacy={true}
                    stepOperations={stepOperations}
                    collectorComponent={stepProfile[stepData.display].collectorComponent}
                />
            </Box>}
    </div>
    );
};

const Step = ({ stepData, stepIndex, stepId }) => {
    return <>
        {stepData.initialized ? <StepConfiguration
            stepData={stepData}
            stepId={stepId}
            stepIndex={stepIndex} /> : <Box
                sx={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3 }}>
                    <AutomataStepCreation
                stepData={stepData}
                stepId={stepId}
                stepIndex={stepIndex}
            /></Box>}
    </>
};

export default Step;