import React, { useState, useRef, useEffect } from 'react';
import { ListItemButton, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import { useTheme } from '@emotion/react';
import LexTypography from '../../../Lex/LexTypography';
import LexHeaderDescription from '../../../Lex/LexHeaderDescription';

const AutomataExpandableDescription = ({ options }) => {
    return <LexHeaderDescription header={options.header} description={options.description} />

};


// const AutomataExpandableDescription = ({ options }) => {
//     const [descriptionExpanded, setDescriptionExpanded] = useState(true);
//     const theme = useTheme();
//     let splitDescription = options.description.split("\n")
//     return (
//             <Accordion
//                 sx={{ 
//                     padding:2,
//                     border:1,
//                     borderRadius:2,
//                     borderColor: 'divider',
//                     bgcolor: 'background.paper', 
//                     boxShadow: 0,
//                     marginBottom: 2,
//                 }} 
//                 disableGutters 
//                 expanded={descriptionExpanded}
//                 onChange={(e) => setDescriptionExpanded((pre) => !pre)}>
//                 <AccordionSummary
//                     expandIcon={descriptionExpanded ? <DoDisturbOnOutlinedIcon fontSize="small" /> : <HelpOutlineOutlinedIcon fontSize="small" />}

//                 >
//                     <Typography variant="h6">{options.header}</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails style={{ marginLeft: "-15px" }}>
//                     {splitDescription.map((line, index) => {
//                         if (line === "") {
//                             return <br />
//                         }
//                         else {
//                             return <Typography variant="body1">{line}</Typography>
//                         }
//                     })}
//                 </AccordionDetails>
//             </Accordion>
//     );
// };


export default AutomataExpandableDescription;