
import React from 'react';
import IDIStepCollector from '../../../components/Automata/IDI/API_IDIStepCollector';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import IDITestDisplay from '../../../components/Automata/IDI/API_IDITestDisplay';
import { Box } from '@mui/material';
import LexTypography from '../../../components/Lex/LexTypography';

export const idiStep = {
    stepMenuOptions: ['idiSelect','stepMetaData','outputDisplay'],
    initializedStep:'idiSelect',
    execution: (props)=>
        <Box sx={{padding:2,border:1,borderRadius:3,borderColor:'action.disabled',marginTop:3}}>
        <LexTypography  sx={{paddingBottom:2}}  variant="h6">IDI Query Output</LexTypography>
        <IDITestDisplay {...props} />
        </Box>,
    continueCondition: ({ stepData, testState }) => true,
    executionSingleStepText: 'Execute IDI Query',
    overview: {
        name: 'idiStep',
        label: 'Intelligent Dynamic Integration',
        sublabel: 'Utilize Intelligent Dynamic Integration to query external search engines and retrieve the results. (DEV --  v.0.58) ',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'idiStep',
                labelDisplay: "IDI",
                display: 'idiSelect',
                initialized: true
            })
        }
    },
    outputDisplay: {
        label: 'Test Output',
        enabled: true,
        collectorComponent: props=><IDITestDisplay {...props} />

    },
    idiSelect: {
        label: 'Step Configuration',
        useExpandableDescription: true,
        header: 'IDI Configuration',
        description: 'Please provide the relevant data needed to browse the external website.',
        collectorComponent: (props) => <IDIStepCollector {...props} />,
    },
    default: {

    }


};
