import React from "react";
import Registration from "./registration/registration";
import Login from "./login";
import ForgotPassword from "./forgotPassword";
import Sample from "./sample";
import axios from "axios";
import { BASE_URL } from "../constants/links";
import Phone from "./phone";
import { auth } from "./auth";
import { onAuthStateChanged } from "firebase/auth";

export const goToMain = (
    navigate,
    onLogout = () => { }
) => {
    onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in
            navigate('/main')
        } else {
            // User is signed out
            onLogout()
        }
    });
}

export const handleAuthError = (error) => {
    console.error(error)
    if (error.code) {
        return handleFirebaseError(error)
    } else if (error.message) {
        return error.message
    } else {
        return 'Unknown error.'
    }
}

export const handleFirebaseError = (e) => {
    switch (e.code) {
        case 'auth/invalid-email':
            return 'Invalid email address format.';
        case 'auth/user-disabled':
            return 'This account has been disabled.';
        case 'auth/wrong-password':
            return 'Incorrect password.';
        case 'auth/invalid-verification-code':
            return "Invalid verification code."
        case 'auth/missing-verification-code':
            return "Missing verification code."
        case 'auth/code-expired':
            return "Verification code expired."
        case 'auth/invalid-phone-number':
            return "Invalid phone number."
        case 'auth/missing-phone-number':
            return "Missing phone number."
        case 'auth/quota-exceeded':
            return "Quota exceeded."
        case 'auth/operation-not-allowed':
            return "Operation not allowed."
        default:
            return e.message;
    }
}

export const axiosWrapper = async (
    url,
    data = {},
    options = { headers: {} }
) => {
    // Clone options to avoid mutating input
    const optionsCopy = { ...options, headers: { ...options.headers } };

    // Add authentication token if available
    if (auth.currentUser) {
        const idToken = await auth.currentUser.getIdToken(/* forceRefresh */ true);
        if (idToken) {
            optionsCopy.headers["X-PATENTIST-ID-TOKEN"] = idToken;
        }
    }
    // Execute the API call and await the response
    const response = await axios.post(BASE_URL + url, data, optionsCopy);
    return response; // Return the data field for convenience
}

function Auth() {

    return (
        <div style={{ backgroundColor: "black", height: "100vh" }}>
            <Login />
            <div style={{ height: "2px", backgroundColor: "darkgray" }} />
            <Phone />
            <div style={{ height: "2px", backgroundColor: "darkgray" }} />
            <Registration />
            <div style={{ height: "2px", backgroundColor: "darkgray" }} />
            <ForgotPassword />
            <div style={{ height: "2px", backgroundColor: "darkgray" }} />
            <Sample />
        </div>
    );
}

export default Auth