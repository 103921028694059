import React, { useState } from "react";
import { Box, useTheme } from '@mui/material';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import { Logo } from '../../logoComponent.js';
import AutomationSidebar from './AutomationSidebar/index.js';
import PatentSidebar from "./PatentSidebar/index.js";
import LandingSidebarMenu from "./LandingSidebarMenu/index.js";
import OASidebar from "./OASidebar/index.js";

const LandingSidebar = (props) => {
    const drawerOpen = props.drawerOpen;
    const toggleDrawer = props.toggleDrawer;
    const theme = useTheme();
    const [sidebarType, setSidebarType] = useState(0)
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const changeHandler = (e, newValue) => {
        setSidebarType(newValue)
        if (newValue === 1) {
            props.setOpenPage("flow")
        }
    }
    const [expanded, setExpanded] = useState(null)
    const handleChange = (panel) => {
        setExpanded(old => old === panel ? null : panel);
    };
    const sidebarContent = (<Box
        dispay="flex"
        sx={{
            bgcolor: 'background.sidebar',
            borderRight: 1,
            borderColor: "action.disabled"
        }}
        style={{
            height: "100dvh",
            width: isMobile ? '280px' : "280px",
            minWidth: isMobile ? '280px' : "280px",
            position: 'relative',
            flexDirection: 'column',
        }}>
        <Box
            display='flex'
            sx={{
                bgcolor: 'background.sidebar',
                paddingTop: 3
            }}
            style={{
                height: "150px",
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
            <Logo
                setOpenPage={() => { }}
                format="text-alt2"
                style={{ flex: '0 1 fit-content' }} />
        </Box>
        <Box sx={{ bgcolor: 'background.sidebar' }} style={{ height: "calc(100dvh - 210px)", width: "100%", overflowY: "overlay" }}>
            <AutomationSidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
            <PatentSidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
            <OASidebar expanded={expanded} handleChange={handleChange} docstyle={{ flex: '1 1 auto', overflow: 'hidden' }} {...props} />
        </Box>

        <LandingSidebarMenu toggleColorMode={props.toggleColorMode} />
    </Box>)

    return isMobile ? (
        <SwipeableDrawer
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
        >
            {sidebarContent}
        </SwipeableDrawer>
    ) : sidebarContent
}

export default LandingSidebar;