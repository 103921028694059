import React, { useContext } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import Checkbox from '@mui/material/Checkbox';
import useHiddenState from '../../Common/hooks/useHiddenState';
import DesContext from '../../../../contexts/singleFlow/context';

export const DisplayCitation = ({ citationData }) => {
    const { name, fileName,section, pageNum, lineNums } = citationData;

    return (
        `${fileName+"   "}${section ? `, Section: ${section}` : ''}(${pageNum ? `Page: ${pageNum}` : ''}}`
    );
};

const GenerativeSearchTestDisplay = ({ fixHeight, configureStep, stepData, errorDisplay, testState, stepId }) => {
    const { retrieveHiddenState,embedHiddenState } = useHiddenState(testState, configureStep, stepId, 2);
    const { manager } = useContext(DesContext);
    let searchResults = retrieveHiddenState('search__underlying', []);
    let activeDocs = retrieveHiddenState('active_docs', []);

    const handleToggle = (index) => () => {
        const newActiveDocs = [...activeDocs];
        newActiveDocs[index] = !newActiveDocs[index];
        embedHiddenState('active_docs', newActiveDocs)
    };

    return (
        <List sx={{ width: '100%',height: fixHeight || null, overflowY: fixHeight ? 'scroll' : null }}>
            {searchResults.map((result, index) => {
                const labelId = `checkbox-list-label-${index}`;

                return (
                    <ListItem
                        key={index}
                        secondaryAction={
                            <IconButton edge="end" aria-label="download" onClick={() => {
                                manager.showCitation({
                                    name: `Result ${index + 1}`,
                                    fileName: result['file'],
                                    pageNum: result['page']?.['page_number'] + '',
                                });
                            }}>
                                <DownloadIcon />
                            </IconButton>
                        }
                        dense
                        disablePadding
                    >
                        <ListItemButton role={undefined} dense>
                            <ListItemIcon>
                                <Checkbox
                                    onClick={handleToggle(index)}
                                    edge="start"
                                    checked={activeDocs[index]} 
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                id={labelId}
                                primary={
                                    <DisplayCitation
                                        citationData={{
                                            name: `Result ${index + 1}`,
                                            fileName: result['file'],
                                            pageNum: result['page']?.['page_number'] + '',
                                        }}
                                    />
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    );
};

export default GenerativeSearchTestDisplay;
