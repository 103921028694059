import React, { useContext, useEffect } from "react";
import { SideCard } from "../../../cards.js";
import LexStandardList from "../../../Lex/LexStandardList/index.js";
import WorkflowContext from "../../../../contexts/workflow/context.js";
import LexExpandableAccordion from "../../../Lex/LexExpandableAccordion/index.js";
import LexTypography from "../../../Lex/LexTypography/index.js";
import { AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagonalSplitter from "../../../Design/DiagonalSplitter/index.js";

const AutomationSidebar = (props) => {
    const { workflows, manager } = useContext(WorkflowContext)
    useEffect(() => {
        if(manager.forceSwitchToFlow > 1){
            props.setOpenPage("flow")
            if(props.expanded !== 'panel1'){
            props.handleChange('panel1')
            }
        }
        },[manager.forceSwitchToFlow])
    return (
        <div style={{
            ...props.docstyle,
            display: 'flex',
            flexDirection: 'column',
        }}>
                <LexExpandableAccordion
                    expanded={props.expanded === 'panel1'} onChange={()=>props.handleChange('panel1')}
                    Summary={   
                        ()=>(<AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <DiagonalSplitter width={25} height={40} outer="rgba(0,0,0,.1)" middle="rgba(0,0,0,0)" inner="rgba(0,0,0,.3)"/>
                            <div style={{
                            paddingLeft:"50px",
                            width:"100%"}}>
                            <LexTypography sx={{ fontWeight: 500 }}>Automations</LexTypography>
                            </div>
                        </AccordionSummary>)
                    }
                    details={<LexStandardList
                        items={workflows}
                        onItemClick={(flowId)=>{
                            manager.activateWorkflow(flowId.id)
                            props.setOpenPage("flow")
                        }}
                        addNewItem={manager.createWorkflow}
                        newItemText={"New Workflow"} />
                    } />
        </div>
    );
};

export default AutomationSidebar;