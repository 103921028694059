import { useFileHandlers } from "../../../utilities/useFileHandlers";

export const useImportExport = (api, activeWorkflow, loadWorkflowGeneral, saveWorkflow) => {

    
    const importWorkflow = (flow) => {
        loadWorkflowGeneral(flow, flow.metadata)
        saveWorkflow(1000)
    }
    const { handleFileLoad, downloadObjectAsJson } = useFileHandlers(importWorkflow);

    const exportWorkflow = () => {
        api('/ptntst/getWorkflow', { flowId: activeWorkflow }).then(res => {
            downloadObjectAsJson({ flow: res.data.flow }, `${res.data.flow.name}.json`)
        })
    }

    

    return { exportWorkflow, handleFileLoad }

}