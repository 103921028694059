import React, { useEffect, useContext, useState } from 'react';
import 'reactflow/dist/style.css';
import {
    Button,
    TextField,
    FormControlLabel,
    ToggleButton,
    ToggleButtonGroup,
    Checkbox,
    Typography,
    Tabs,
    Tab, Modal, CircularProgress,
    Box,
    FormControl,
    Select,
    Accordion, AccordionSummary, AccordionDetails,
    MenuItem,
    InputLabel,
    useTheme
} from '@mui/material';
import { useFileHandlers } from '../hooks';
import makePromptDescriptionOfGraph from './promptFn';
import { MarkerType } from 'reactflow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NodeEdgeContext from '../../contexts/figureDesign/context';
import SettingsIcon from '@mui/icons-material/Settings';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import ClearContext from '../../contexts/figureDesignClearContext/context';
import html2canvas from 'html2canvas';
import APIContext from '../../contexts/api/context';
import ConfirmationModal from '../../components/Modals/ConfirmationModal';

const LoadingModal = (props) => (
    <Modal
        open={props.loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
    >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </Box>
    </Modal>
);


const NewFigureConfirmation = ({ setClearVer }) => {
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const handleClose = () => { setOpenConfirmation(false) };
    return (
        <>
            <Button
                variant="contained" onClick={() => setOpenConfirmation(true)}>
                New Figure
            </Button>
            <ConfirmationModal
                confirmationTitle={"Confirm Selection"}
                confirmationText={"Are you sure you wish to start a new figure? If not already generated, your current figure will be lost."}
                open={openConfirmation}
                handleClose={handleClose}
                onConfirm={() => setClearVer(v => v + 1)} />
        </>
    );
};

const MySelectorWithConfirmation = ({ dataObject, loadFn }) => {
    const [selectedKey, setSelectedKey] = useState('');
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleChange = (event) => {
        setSelectedKey(event.target.value);
        setOpenConfirmation(true);
    };

    const handleClose = () => {
        setOpenConfirmation(false);
    };

    const handleConfirm = () => {
        if (loadFn && typeof loadFn === 'function') {
            loadFn(dataObject[selectedKey], selectedKey);
        }
        setOpenConfirmation(false);
    };

    return (
        <div>
            <FormControl fullWidth>
                <Select
                    value={selectedKey}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>
                        Select an option
                    </MenuItem>
                    {Object.keys(dataObject).filter(key => key !== "init").map((key) => (
                        <MenuItem key={key} value={key}>
                            Figure {key}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <ConfirmationModal
                confirmationTitle={"Confirm Selection"}
                confirmationText={`Are you sure you want to load the data for Figure ${selectedKey}, this will erase the current figure?`}
                open={openConfirmation}
                handleClose={handleClose}
                onConfirm={handleConfirm} />
        </div>
    );
};


const arrowhead = {
    type: MarkerType.ArrowClosed,
    width: 15,
    height: 15,
}

const cap = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function EdgeTabPanel({
    edges,
    handleEdgeChange,
    selectedEdge
}) {

    const [options, setOptions] = useState({
        source: "1",
        target: "2",
        label: "",
        directionless: false,
        bothWays: false,
    });

    const edgePrep = () => {
        // Separate selected edge from the rest of the edges.
        let [edge, rest] = edges.reduce((acc, e) => {
            if (e.id === selectedEdge)
                acc[0].push(e);
            else
                acc[1].push(e);

            return acc;
        }, [[], []]);

        return [edge, rest]
    }

    const updateArrows = (edge) => {
        // reset arrowhead data
        delete edge.markerEnd
        delete edge.markerStart
        if (!edge.directionless) {
            edge.markerEnd = arrowhead
            if (edge.bothWays) edge.markerStart = arrowhead
        }

        return edge
    }

    const updateOptions = () => {
        let [edge, rest] = edgePrep()
        edge = edge[0]
        setOptions({
            source: edge.source,
            target: edge.target,
            label: edge.label,
            directionless: edge.directionless,
            bothWays: edge.bothWays,
        })
    }

    const edgeWrapper = (operations) => (event) => {
        // separate edge out
        let [edge, rest] = edgePrep()

        // call operation with data
        edge = operations(event, edge[0], rest)

        // if no edge returned, just delete it.
        if (!edge) {
            handleEdgeChange(rest, true)
            return
        }

        // update arrowheads
        edge = updateArrows(edge)

        // save changes
        handleEdgeChange([...rest, edge])
    }

    const deleteEdge = edgeWrapper((event, edge, rest) => null)

    const swapDirection = edgeWrapper((event, edge, rest) => ({
        ...edge,
        source: edge.target, target: edge.source,
        sourceHandle: edge.targetHandle, targetHandle: edge.sourceHandle
    }))

    const setDirectionless = edgeWrapper((event, edge, rest) =>
        ({ ...edge, directionless: !edge.directionless }))

    const setBothWays = edgeWrapper((event, edge, rest) =>
        ({ ...edge, bothWays: !edge.bothWays }))

    const setLabel = edgeWrapper((event, edge, rest) =>
        ({ ...edge, label: event.target.value }))

    useEffect(updateOptions, [edges, selectedEdge])

    if (!selectedEdge) return <div>No edge selected.</div>

    return (
        <div>


            <div>
                <div style={{ paddingTop: "30px" }}>
                    <TextField
                        id="Label"
                        label="Label"
                        value={options.label}
                        fullWidth
                        name="label"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={setLabel} />
                </div>
                <div style={{ paddingTop: "30px" }}>
                    <Typography variant="body2" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Edge Options</Typography>
                    <div style={{ padding: "5px 15px", border: "solid 1px #444", borderRadius: "5px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={options.directionless}
                                    onChange={setDirectionless}
                                    name={"directionless"} />}
                            label={"Directionless"} />
                        {options.directionless
                            ? null
                            : <FormControlLabel
                                control={
                                    <Checkbox checked={options.bothWays}
                                        onChange={setBothWays}
                                        name={"bothways"} />}
                                label={"Both Ways"} />}
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                alignItems: "space-around",
                paddingTop: "30px"
            }}>
                {options.directionless || options.bothWays
                    ? null
                    : <Button variant="contained" onClick={swapDirection}>
                        Swap Direction</Button>}
                <Button variant="contained" onClick={deleteEdge} color="warning">
                    Delete Edge</Button>
            </div>
        </div>
    )
}

const genericHandlerString = (string, state, setState, modifyNodeData, selectedNode, isCheckBox) => (event) => {
    let modified
    if (isCheckBox) {
        modified = { ...state, [event.target.name]: event.target.checked };
    } else {
        modified = { ...state, [string]: event.target.value };

    }
    setState(modified);
    modifyNodeData(selectedNode, modified);
};

const useStateHandler = (defaultState, string, modifyNodeData, selectedNode, isCheckBox) => {
    const [state, setState] = useState({ [string]: defaultState });
    useEffect(() => {
        setState({ [string]: defaultState })
    }, [selectedNode])
    return [state, setState, genericHandlerString(string, state, setState, modifyNodeData, selectedNode, isCheckBox)]

}

function ShapeSelector({ disabled, boxStyle, setBoxStyle, handleBoxStyleChange }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="shape-selector-label">Shape</InputLabel>
            <Select
                disabled={disabled}
                labelId="shape-selector-label"
                id="shape-selector"
                value={boxStyle.boxStyle}
                label="Shape"
                onChange={handleBoxStyleChange}
            >
                <MenuItem value="rectangle">Rectangle</MenuItem>
                <MenuItem value="circle">Circle</MenuItem>
                <MenuItem value="diamond">Diamond</MenuItem>
            </Select>
        </FormControl>
    );
}

function downloadReactFlowScreenshot(elementId) {
    return new Promise((resolve, reject) => {
        const element = document.getElementById(elementId); // Get the react-flow element by its ID

        if (element) {
            html2canvas(element).then((canvas) => {
                // Create an image from the canvas
                canvas.toBlob((blob) => {
                    if (blob) {
                        resolve(blob);
                    } else {
                        reject(new Error('Blob creation failed'));
                    }
                }, 'image/png');
            });
        } else {
            reject(new Error(`Element not found: ${elementId}`));
        }
    });
}

function Sidebar({ patentId }) {
    const { api } = useContext(APIContext);
    const { clearVer, setClearVer } = useContext(ClearContext);
    const [loading, setLoading] = useState(false);
    const {
        nodes,
        edges,
        setNodes,
        selectedNode,
        setVer,
        deleteNodeAndEdgesById,
        getScreenshot,
        selectedNodeFull,
        selectedEdge,
        setSelectedEdge,
        changeEdge,
        downloadReactFlowScreenshotFromCTX,
        modifyNodeData,
        value,
        figureId,
        setFigureId,
        setValue,
        recomputeGroups,
        handleAddNode,
        toggleables,
        handleToggle,
        setEdges,
        landscape,
        setLandscape } = useContext(NodeEdgeContext);
    const handleLabels = ["top", "bottom", "left", "right"]
    const [promptData, setPromptData] = useState({ figureNumber: "0", generated: false, background: "", claims: "", additional: "" })
    const { handleFileLoad, handleFileLoadWithState, downloadObjectAsJson } = useFileHandlers(setNodes, setEdges, promptData, setPromptData)
    const [returnData, setReturnData] = useState({});
    const [figures, setFigures] = useState({});
    const [systemsDiagram, setSystemsDiagram] = useState(false);
    const theme = useTheme();
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const [figureVisible, setFigureVisible] = useState(false);
    const [figureNumId, setFigureNumId] = useState("");
    const [boxStyle, setBoxStyle, handleBoxStyleChange] = useStateHandler(
        selectedNodeFull?.data?.boxStyle ? selectedNodeFull.data.boxStyle : "rectangle",
        "boxStyle",
        modifyNodeData,
        selectedNode,
        false);

    const [groupId, setGroupId, handleGroupIdChange] = useStateHandler(
        selectedNodeFull?.data?.groupId ? selectedNodeFull.data.groupId : "",
        "groupId",
        modifyNodeData,
        selectedNode,
        false);
    //a
    const [useDashedLines, setUseDashedLines, handleUseDashedLinesChange] = useStateHandler(
        !!selectedNodeFull?.data?.useDashedLines,
        "useDashedLines",
        modifyNodeData,
        selectedNode,
        true);

    const [groupNode, setGroupNode, handleGroupNodeChange] = useStateHandler(
        !!selectedNodeFull?.data?.groupNode,
        "groupNode",
        modifyNodeData,
        selectedNode,
        true);

    const [groupMember, setGroupMember, handleGroupMemberChange] = useStateHandler(
        !!selectedNodeFull?.data?.groupMember,
        "groupMember",
        modifyNodeData,
        selectedNode,
        true);

    const [useComponentNumber, setUseComponentNumber, handleComponentNumChange] = useStateHandler(
        !!selectedNodeFull?.data?.useComponentNumber,
        "useComponentNumber",
        modifyNodeData,
        selectedNode,
        true);

    const [componentNumber, setComponentNumber, handleComponentNumInputChange] = useStateHandler(
        selectedNodeFull?.data?.componentNumber ? selectedNodeFull.data.componentNumber : 0,
        "componentNumber",
        modifyNodeData,
        selectedNode,
        false);

    const [description, setDescription, handleDescriptionChange] = useStateHandler(
        selectedNodeFull?.data?.description ? selectedNodeFull.data.description : "",
        "description",
        modifyNodeData,
        selectedNode,
        false);

    const [label, setLabel, handleLabelChange] = useStateHandler(
        selectedNodeFull?.data?.label ? selectedNodeFull.data.label : "placeholder",
        "label",
        modifyNodeData,
        selectedNode,
        false);

    useEffect(() => {
        api('/ptntst/getFigures', { patentId }).then(res => {
            setFigures(res.data.figures)
        })
    }, [])



    const handleEdgeChange = (newEdges, deleted = false) => {
        if (deleted) setValue(0)
        changeEdge(newEdges, deleted)
    }

    const handleToggleChange = (event, newToggle) => {
        handleToggle(event, newToggle)
        modifyNodeData(selectedNode, {
            ...selectedNodeFull.data,
            resizable: newToggle.includes('resize'),
            locked: newToggle.includes('locked'),
            getDimensions: true,
        })
    }

    const graphDescription = makePromptDescriptionOfGraph(nodes, edges, promptData);
    const loadExistingFigure = (data, key) => {
        setSystemsDiagram(data.systemsDiagram)
        setFigureId(data.figureId)
        setNodes(data.nodes)
        setEdges(data.edges)
        setPromptData(data.promptData)
        setFigureNumId(key)
        setFigureVisible(!!data.visible)
        setReturnData({ brief: data.brief, detailed: data.detailed })
    }
    const groupNodes = nodes.filter(node => (node.data.groupNode && node.id !== selectedNode));
    const groupNodeLabelPairs = groupNodes.map(node => ({ label: node.data.label, id: node.id }))
    const groupNodeId = nodes.filter(node => node.id === groupId.groupId)[0]?.id
    return (
        <div style={{ width: "100%", height: "100%", backgroundColor: theme.palette.background.paper, borderLeft: "1px solid black" }}>
            {/* <Box sx={{ width: '100%',height: "100%" }}> */}
            <LoadingModal loading={loading} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Node" {...a11yProps(0)} />
                    <Tab label="Edge" {...a11yProps(1)} disabled={!selectedEdge} />
                    <Tab label="Generate" {...a11yProps(2)} />
                    <Tab icon={<SettingsIcon />}{...a11yProps(3)} />
                </Tabs>
            </Box>
            <div style={{ overflowY: "scroll", height: "93%" }}>
                <CustomTabPanel value={value} index={0} >

                    {selectedNode ? <div>
                        <div style={{ paddingTop: "30px" }}>
                            <TextField
                                id="Label"
                                label="Label"
                                multiline
                                value={label.label}
                                fullWidth
                                name="label"
                                disabled={toggleables.includes('resize')}
                                onChange={handleLabelChange} />
                            <div style={{ paddingTop: "30px" }}>
                                <TextField
                                    id="Description"
                                    label="Description"
                                    value={description.description}
                                    fullWidth
                                    name="Description"
                                    multiline
                                    onChange={handleDescriptionChange} /></div>
                            <div style={{ paddingTop: "30px" }}>
                                <ShapeSelector
                                    disabled={toggleables.length > 0}
                                    boxStyle={boxStyle} setBoxStyle={setBoxStyle}
                                    handleBoxStyleChange={handleBoxStyleChange} />
                            </div>
                            {groupMember.groupMember ? <div style={{ paddingTop: "30px" }}>
                                <Typography variant="body2" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Group Label:</Typography>
                                <div style={{ padding: "5px 15px", border: "solid 1px #444", borderRadius: "5px" }}>

                                    <Select
                                        value={groupNodeId}
                                        onChange={(e) => {
                                            handleGroupIdChange(e)
                                        }}
                                        variant={'filled'}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        // label={"aaawfawfwaf"}
                                        // displayEmpty
                                        la
                                        fullWidth
                                    // inputProps={{ 'aria-label': 'Without label' }}
                                    >

                                        {groupNodeLabelPairs.map((obj) => (
                                            <MenuItem key={"groupNodeLabelKey" + obj.id} value={obj.id}>
                                                {obj.label}
                                            </MenuItem>
                                        ))}
                                        <MenuItem value="" disabled>
                                            {groupNodeLabelPairs.length === 0 ? " (No group available)" : "Select an option"}
                                        </MenuItem>
                                    </Select></div>
                            </div> : null}

                            <div style={{ paddingTop: "30px" }}>
                                <Typography variant="body2" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Node Options</Typography>
                                <div style={{ padding: "5px 15px", border: "solid 1px #444", borderRadius: "5px" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={toggleables.includes('resize')}
                                                checked={useDashedLines.useDashedLines}
                                                onChange={handleUseDashedLinesChange}
                                                name={"useDashedLines"} />}
                                        label={"Dashed Lines"}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={toggleables.includes('resize')}
                                                checked={groupMember.groupMember}
                                                onChange={(e) => {
                                                    if (groupMember.groupMember) {
                                                        handleGroupIdChange({ target: { value: "" } })
                                                    }
                                                    handleGroupMemberChange(e)
                                                }}
                                                name={"groupMember"} />}
                                        label={"Group subordinate"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={toggleables.includes('resize')}
                                                checked={groupNode.groupNode}
                                                onChange={(e) => {
                                                    handleGroupNodeChange(e)
                                                    setTimeout(recomputeGroups, 100)
                                                }}
                                                name={"groupNode"} />}
                                        label={"Group node"}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={toggleables.includes('resize')}
                                                checked={useComponentNumber.useComponentNumber}
                                                onChange={handleComponentNumChange}
                                                name={"useComponentNumber"} />}
                                        label={"Use Component Number?"}
                                    />
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-evenly",
                                    alignItems: "space-around",
                                    paddingTop: "30px",
                                    paddingBottom: "30px",
                                }}>
                                    {useComponentNumber.useComponentNumber ?
                                        <TextField
                                            id="Component Number"
                                            label="Component Number"
                                            fullWidth
                                            value={componentNumber.componentNumber}
                                            name="Component Number"
                                            onChange={handleComponentNumInputChange} /> : null}

                                    <ToggleButtonGroup
                                        value={toggleables}
                                        onChange={handleToggleChange}>
                                        <ToggleButton value="resize" variant="contained" disabled={!selectedNode}>
                                            Resize</ToggleButton>
                                        <ToggleButton value="locked" variant="contained" disabled={!selectedNode}>
                                            Lock</ToggleButton>
                                    </ToggleButtonGroup>
                                </div>

                            </div>
                        </div>
                    </div> : null}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1} >
                    <EdgeTabPanel selectedEdge={selectedEdge}
                        handleEdgeChange={handleEdgeChange}
                        edges={edges}
                        setSelectedEdge={setSelectedEdge} />

                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "space-around",
                        paddingBottom: "5px"
                    }}>

                    </div>

                    <Typography variant="h5" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Generation</Typography>
                    <Typography variant="body2" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Once you complete you figure, please provide a short description of how your figure relates to your claims and/or invention.
                        This short description will be used alongside the data of the figure to generate both a brief and full detail description of the figure. You do not need to describe the figure, nodes, or edges themselves. </Typography>
                    <div style={{ paddingTop: "30px" }}>
                        <TextField
                            id="Figure Number"
                            label="Figure Number"
                            value={promptData.figureNumber}
                            fullWidth
                            name="Figure Number"
                            onChange={(e) => {
                                setPromptData((oldData) => ({ ...oldData, figureNumber: e.target.value }))
                            }} />
                    </div>
                    <div style={{ paddingTop: "30px" }}>
                        <TextField
                            id="Additional Context/Description"
                            label="Additional Context/Description"
                            value={promptData.background}
                            fullWidth
                            name="Additional Context/Description"
                            multiline
                            onChange={(e) => {
                                setPromptData((oldData) => ({ ...oldData, background: e.target.value }))
                            }} />
                    </div>
                    <div style={{ whiteSpace: 'pre-wrap', paddingTop: "40px", paddingBottom: "15px" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={systemsDiagram}
                                    onClick={() => {
                                        setSystemsDiagram(vis => !vis);

                                    }}
                                    name={"systemsDiagram"} />}
                            label={"Is this an architecture/apparatus diagram?"}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {

                                setLoading(true);
                                downloadReactFlowScreenshotFromCTX('my-react-flow').then(blob => {
                                    const formData2 = new FormData();
                                    formData2.append('file', blob);
                                    formData2.append('data', JSON.stringify({ edges, nodes, systemsDiagram: systemsDiagram, imageBlob: blob, figureNumId, figureId, promptData: promptData, patentId: patentId, graphDescription: graphDescription }));


                                    api('/ptntst/figureEvaluate', formData2, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    }).then(res => {
                                        api('/ptntst/getFigures', { patentId }).then(res => {
                                            setFigures(res.data.figures)
                                        })
                                        setFigureId(res.data.figureId)
                                        setReturnData(res.data)
                                        setFigureNumId(res.data.figureNumberKey)
                                        setLoading(false);
                                    })
                                })
                            }}>
                            Generate Description
                        </Button>
                    </div>
                    {/* <div style={{ paddingTop: "30px" }}>
                        <TextField
                            id="Prompt"
                            label="Prompt"
                            value={promptData.prompt}
                            name="Prompt"
                            fullWidth
                            multiline
                            onChange={(e) => {
                                setPromptData((oldData) => ({ ...oldData, prompt: e.target.value }))
                            }} />
                    </div> */}
                    {returnData.brief ? <Accordion
                        style={{ /* TODO: backgroundColor: "#182223", */ marginTop: 1 > 0 ? "15px" : 'none', boxShadow: 'none', }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <span style={{ transform: "scale(0.65)", marginTop: "-5px", marginBottom: "-5px", marginLeft: "-8px" }} ></span>
                            <Typography style={{ fontSize: ".8em" }}><b>Brief Description</b></Typography>


                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                {returnData.brief}
                            </Typography>
                        </AccordionDetails>
                    </Accordion> : null}
                    {returnData.detailed ? <Accordion
                        style={{ /* TODO: backgroundColor: "#182223", */ marginTop: 0 > 0 ? "15px" : 'none', boxShadow: 'none', }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <span style={{ transform: "scale(0.65)", marginTop: "-5px", marginBottom: "-5px", marginLeft: "-8px" }} ></span>
                            <Typography style={{ fontSize: ".8em" }}><b>Detailed Description</b></Typography>


                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                {returnData.detailed}
                            </Typography>
                        </AccordionDetails>
                    </Accordion> : null}

                    {/* <Accordion
                        style={{ marginTop: 0 > 0 ? "15px" : 'none', boxShadow: 'none', }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <span style={{ transform: "scale(0.65)", marginTop: "-5px", marginBottom: "-5px", marginLeft: "-8px" }} ></span>
                            <Typography style={{ fontSize: ".8em" }}><b>Graph Description</b></Typography>


                        </AccordionSummary>
                        <AccordionDetails>

                            <Typography style={{ whiteSpace: 'pre-wrap' }}>
                                {graphDescription}
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                    <Typography variant="h6" style={{ paddingTop: "10px", paddingBottom: "10px" }}>Load Existing Figure</Typography>
                    <MySelectorWithConfirmation dataObject={figures} loadFn={loadExistingFigure} />
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        alignItems: "space-around",
                        paddingBottom: "30px",
                        paddingTop: "30px"
                    }}>
                        <NewFigureConfirmation setClearVer={setClearVer} />
                    </div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={figureVisible}
                                onClick={() => {
                                    setFigureVisible(vis => !vis);
                                    setLoading(true);
                                    downloadReactFlowScreenshotFromCTX('my-react-flow').then(blob => {
                                        const formData2 = new FormData();
                                        formData2.append('file', blob);
                                        formData2.append('data', JSON.stringify({ patentId: patentId, figureNumberKey: figureNumId }));
                                        api('/ptntst/toggleFigureVisible', formData2, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data'
                                            }
                                        }).then(res => {
                                            api('/ptntst/getFigures', { patentId }).then(res2 => {
                                                setFigures(res2.data.figures)
                                                setLoading(false)
                                            })
                                        })
                                    })

                                }}
                                name={"figureVisible"} />}
                        label={"Render figure in final document?"}
                    />
                </CustomTabPanel>
            </div>
            {/* </Box> */}
        </div>
    );
}

export default Sidebar;