import { useContext } from 'react';
import LexFileDropzone from '../../../Lex/LexFileDropzone';
import DesContext from '../../../../contexts/singleFlow/context';
import { Box } from '@mui/material';
import useHiddenState from '../../Common/hooks/useHiddenState';

const DynamicFileCollector = ({ fixHeight, configureStep, stepData, errorDisplay, stepIndex, stepId, testState }) => {
    const { retrieveHiddenState, embedHiddenState } = useHiddenState(testState, configureStep, stepId, 2)
    let { manager } = useContext(DesContext);
    const mode = 'singleFile'
    const files = retrieveHiddenState('files', [])
    const delFn = manager.deleteFile(mode)
    return (
        <Box>
            <LexFileDropzone
                files={files}
                uploadFuncton={manager.uploadFiles({ configureStep, stepData, testState, stepIndex, stepId, mode })}
                dependencies={[configureStep, stepData]}
                deleteFunction={(index) => () => delFn({ configureStep, stepData, testState, files, stepIndex, stepId, file_idx: index })}
            />
        </Box>)
}

export default DynamicFileCollector;