import { useRef } from 'react';
import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const TOOLTIP_TRUNCATE_LENGTH = 150;

const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 300,
        zIndex: 1000000
    },
});

const truncateTooltipText = (text) => {
    if (!text) {
        return 'Nothing here. This variable may not be defined above, or you may need to run the prior steps.'
    } else if (text.length < TOOLTIP_TRUNCATE_LENGTH) {
        return text
    } else {
        return `${text.substring(0, TOOLTIP_TRUNCATE_LENGTH)}... (${text.length - TOOLTIP_TRUNCATE_LENGTH} characters omitted)`
    }
}

const ToolTipPre = ({ outerProps, ...props }) => {

    const {
        localValue,
        showAutocomplete,
        filteredKeys,
        setLocalValue,
        setShowAutocomplete,
        setFilteredKeys
    } = outerProps;

    const autocompleteRef = useRef(null);
    const keyExists = outerProps?.keysObject?.[outerProps.key]
    const keyIsValid = outerProps?.possibleKeyObject?.[outerProps.key]
    const validButDoesntExist = !!keyIsValid && !!!keyExists
    const tooltipText = keyIsValid ? (keyExists ? keyExists + "" : keyIsValid + " Not available in previous step.") : ""
    const handleSuggestionClick = (suggestion) => {
        const newValue = localValue.replace(/\$[a-zA-Z0-9_]*$/, `$${suggestion} `);
        setLocalValue(newValue);
        setShowAutocomplete(false);
    };

    if (outerProps.key === "step") {
        return (<mark style={{ background: 'linear-gradient(khaki, yellow)', position: 'relative', border: '1px solid rgb(88, 142, 173)', borderRadius: "7px", color: 'transparent', zIndex: 100 }} className={props.className}>
            {props.children}
            <span style={{ position: 'absolute', paddingLeft: "6px", paddingBottom: "20px", top: -2, left: 0, color: "black", zIndex: 20000, fontSize: ".9em " }}>{outerProps.key}</span>
        </mark>)
    }
    return (
        <CustomWidthTooltip
            // toolTipSx={validButDoesntExist?{color:'primary.main'}:null}
            title={truncateTooltipText(tooltipText)}>
            {keyIsValid
                ? <mark className={props.className} style={{
                    background: keyExists ? 'linear-gradient(#d3ffea, lightblue)' : 'linear-gradient(lightpink, lightpink)',
                    position: 'relative',
                    border: '1px solid rgb(88, 142, 173)',
                    borderRadius: "7px",
                    color: 'transparent',
                    zIndex: 100
                }}>
                    {props.children}
                    <span style={{
                        position: 'absolute',
                        paddingLeft: "6px",
                        paddingBottom: "20px",
                        top: -2,
                        left: 0,
                        color: "black",
                        zIndex: 20000,
                        fontSize: ".9em "
                    }}>{outerProps.key}
                        {/* {showAutocomplete && outerProps.key && (
                <ul
                    style={{
                        position: 'relative',
                        top: 10,
                        left: 0,
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        zIndex: 1000000,
                        maxHeight: '150px',
                        overflowY: 'auto',
                        listStyleType: 'none',
                        padding: '5px',
                        margin: 0,
                        width: '200px'
                    }}
                >
                    {filteredKeys.map((key) => (
                        <li
                            key={key}
                            onClick={() => handleSuggestionClick(key)}
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            {key}
                        </li>
                    ))}
                </ul>
            )} */}
                    </span>
                </mark>
                : <mark className={props.className}>
                    {props.children}
                    {/* {showAutocomplete && (
                <ul
                    style={{
                        position: 'relative',
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        zIndex: 1000000,
                        maxHeight: '150px',
                        overflowY: 'auto',
                        listStyleType: 'none',
                        padding: '5px',
                        margin: 0,
                        width: '200px'
                    }}
                >
                    {filteredKeys.map((key) => (
                        <li
                            key={key}
                            onClick={() => handleSuggestionClick(key)}
                            style={{ padding: '5px', cursor: 'pointer' }}
                        >
                            {key}
                        </li>
                    ))}
                </ul>
            )} */}
                </mark>
            }
        </CustomWidthTooltip>
    );
}


const ToolTip = (outerProps) => {
    return (props) => <ToolTipPre outerProps={outerProps} {...props} />
}

export default ToolTip;