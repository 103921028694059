import React, { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context.js';
import WorkflowContext from '../../../../contexts/workflow/context.js';
import AutomataSidebar from '../AutomataSidebar/index.js';
import GetStarted from '../AutomataGetStarted/index.js';
import ProgressMonitor from './ProgressMonitor/index.js';
import LowerRightButton from './LowerRightButton/index.js';
import AutomataPreviewMode from '../AutomataPreviewMode/index.js';
import { Box } from '@mui/material';
import AutomataStepWrapper from '../AutomataStepWrapper/index.js';

//#TODO:CSS-CLEAN-UP
const AutomataProcessDesign = () => {
    const { manager } = useContext(DesContext);
    const workflowContextController = useContext(WorkflowContext);
    if (workflowContextController.activeWorkflow === null) { return <GetStarted /> }
    if (manager.previewMode && manager.previewModeActiveStep) {
        return (
            <Box style={{ display: "flex", height: "100vh", width: "100%" }}>
                <Box style={{ position: "relative", height: "100vh", overflowY: "hidden", width: "100%" }}>
                    <AutomataPreviewMode />
                </Box>
                <Box sx={{ width: "430px" }}>
                    <AutomataSidebar />
                </Box>
            </Box>
        );
    } else {
        return (
            <Box sx={{display:'flex', width: "100%" }}>
                <Box sx={{position: "relative",flexGrow: 1}}>
                    <Box
                        sx={{ paddingLeft: 5, paddingRight: 3 }}
                        style={{ height: "100dvh", width: "100%", overflowY: "scroll" }}>
                        <Box sx={{ paddingTop: 8,width: "100%"  }} />
                        {manager.steps.map((step, index) => (
                            <div key={"automata_process_design"+index}>
                            <AutomataStepWrapper step={step} index={index} />
                            </div>
                        ))}
                        <Box sx={{ paddingBottom: 10 }} />
                    </Box>
                    <ProgressMonitor />
                    <LowerRightButton />

                </Box>
                <Box style={{ width: "430px" }}>
                    <AutomataSidebar />
                </Box>
            </Box>
        );
    }
};

export default AutomataProcessDesign;