import { useState, useRef, useEffect, useMemo } from 'react';

const useIsSmallContainer = () => {
    const containerRef = useRef(null);
    const [isSmallContainer, setIsSmallContainer] = useState(false);
  
    useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        if (entries[0]) {
          const width = entries[0].contentRect.width;
          setIsSmallContainer(width <= 550); // Change layout if container width <= 550px
        }
      });
  
      if (containerRef.current) {
        observer.observe(containerRef.current);
      }
  
      // Cleanup observer on unmount
      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }, []);
    return {containerRef, isSmallContainer}
}

export default useIsSmallContainer;