import React from 'react';
import { Box } from '@mui/material';
import LexTypography from '../LexTypography';

const LexHeaderDescription = ({ header,description }) => {
    return (<Box sx={{paddingBottom:1,paddingTop:1}}>
        {header ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="h6">{header}</LexTypography>
        </div> : null}
        {description ? <div style={{ paddingBottom: "20px" }}>
            <LexTypography variant="body1">{description}</LexTypography>
        </div> : null}
        </Box>
    );
};

export default LexHeaderDescription;