import React, { useState, useEffect, useContext } from 'react';
import DocumentViewerContext from './context';
import LexDocumentDisplay from '../../components/Lex/LexDocumentDisplay';
import LexHTMLDisplay from '../../components/Lex/LexHTMLDisplay';

const defaultState = {
    open: false,
    link: "",
    pageNumber: 0,
    highlightText: "",
    htmlMode: false
}
const DocumentViewerContextProvider = ({ children }) => {
    const [documentName, setDocumentName] = useState("Unknown");
    const [documentAuthor, setDocumentAuthor] = useState("Unknown");
    const [documentViewerState, setDocumentViewerState] = useState(defaultState);
    const loadDocument = (link, pageNumber, highlightText, title, author) => {
        setDocumentViewerState({ ...documentViewerState, open: true, link, pageNumber, highlightText, htmlMode:false })
        setDocumentName(title)
        setDocumentAuthor(author)
    }
    const loadExternalHTMLDocument = (htmlString, pageNumber, highlightText, title, author) => {
        setDocumentViewerState({ ...documentViewerState, open: true, htmlString, pageNumber, highlightText, htmlMode:true })
        setDocumentName(title)
        setDocumentAuthor(author)
    }

    const onClose = () => {
        setDocumentViewerState(defaultState)
    };

    return (
        <DocumentViewerContext.Provider value={{
            docView: { loadExternalHTMLDocument,loadDocument }
        }}>
            {documentViewerState.htmlMode ? <LexHTMLDisplay
                  documentName={documentName}
                  documentAuthor={documentAuthor}
                  open={documentViewerState.open}
                  onClose={onClose}
                  htmlString={documentViewerState.htmlString}
            
            
            /> : <LexDocumentDisplay
                    documentName={documentName}
                    documentAuthor={documentAuthor}
                    open={documentViewerState.open}
                    onClose={onClose}
                    link={documentViewerState.link}
                    pageNumber={documentViewerState.pageNumber}
                    highlightText={documentViewerState.highlightText}
                />}
            {children}
        </DocumentViewerContext.Provider>
    );
}

export default DocumentViewerContextProvider;