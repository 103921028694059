import ReactMarkdown from 'react-markdown';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, Link } from '@mui/material';
import DesContext from '../../../contexts/singleFlow/context';
import { useContext } from 'react';


export const DisplayCitation = ({ citationData }) => {
    const { manager } = useContext(DesContext);
    const { name, section, pageNum,lineNums } = citationData;

    return (
        <Link sx={{ color: "primary.tab" }} style={{ cursor: "pointer" }} onClick={() => { manager.showCitation(citationData) }}>
            {"("}
            {name}
            {section && `, Section: ${section}`}
            {pageNum && `, Page: ${pageNum}`}
            {lineNums  && `, Lines: ${lineNums }`}
            {")"}
        </Link>)
}

export const MarkdownTable = ({ markdown, height, whiteSpace }) => {
    const renderers = {
        p: ({ node, ...props }) => <p style={{ marginBottom: '1em', marginTop: '1em' }} {...props} />,
        pre: ({ ...other }) => { return <div>{other['children']}</div> },
        em: ({ ...other }) => { 
            let language = other['className']
            let value = other['children']
            if (typeof(value)==="string"&&  value.startsWith("citation::")) {
                let [init,...rest] = value.split("::")
                let citationDataRaw = rest[0].replace(/\n/g, '').split(',');
                let citationObject = {}
                citationDataRaw.forEach((element) => {
                    let citationData = element.split(':')
                    if (citationData.length === 2) {
                        citationObject[citationData[0]] = citationData[1]
                    }
                })
                return <DisplayCitation citationData={citationObject} />
            }
            return <i>{other['children']}</i> },
        code: ({ ...other }) => {
            let language = other['className']
            let value = other['children']

            if (language === 'language-citation') {
                let citationDataRaw = value.replace(/\n/g, '').split(',');
                let citationObject = {}
                citationDataRaw.forEach((element) => {
                    let citationData = element.split(':')
                    if (citationData.length === 2) {
                        citationObject[citationData[0]] = citationData[1]
                    }
                })
                return <DisplayCitation citationData={citationObject} />
            }
            if (language === 'language-table') {
                const rows = value.trim().split('\n');
                const headers = rows[0].split('|').map(header => header.trim()).filter(Boolean);
                const data = rows.slice(1)
                    .filter(row => !/^[-\s|]+$/g.test(row)) // Filter out delimiter rows
                    .map(row => row.split('|').map(cell => cell.trim()).filter(Boolean));
                return (
                    <div style={{ maxWidth: '100px' }}><br></br>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header, index) => (
                                            <TableCell key={index} align="left" style={{ fontWeight: 'bold' }}>
                                                {header}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <TableRow key={rowIndex}>
                                            {row.map((cell, cellIndex) => (
                                                <TableCell key={cellIndex} align="left">
                                                    {cell}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <br></br>
                    </div>
                );
            }
            return <div className={language} style={{}}>{value}</div>;
        },

    };
    return <div style={{
        whiteSpace: whiteSpace,
        overflowX: 'auto',
        overflowY: 'scroll',
        wordWrap: 'break-word',
        height: height ? height : '500px',
        // overflowWrap: 'break-word',
        wordBreak: 'break-word'
    }}>
        <ReactMarkdown components={renderers}>{markdown}</ReactMarkdown></div>;
}