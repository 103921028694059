import LexFormSelect from '../../../Lex/LexFormSelect';
import LexButton from '../../../Lex/LexButton';
import { Box } from '@mui/material';

const ContextCollector = ({ nextStep, configureStep, stepData, errorDisplay }) => {
    let testOpts = configureStep.priorConversations()
    return (
        <Box>
            <LexFormSelect
                onChange={(e) => { configureStep.set({ contextToContinue: e.target.value }) }}
                selectValue={stepData?.contextToContinue ? stepData?.contextToContinue : null}
                inputLabel={"Existing Conversation"}
                menuText={"Select An Existing Conversation"}
                selectionOptions={testOpts}
                formatMenuItem={([selStr, selIdx]) => "Step " + (1 + selIdx)}

            />
            <Box
                style={{
                    paddingTop: "10px"
                }}>
                <LexButton
                    variant="contained"
                    onClick={(e) => {configureStep.set({ display: nextStep });}}>
                    Continue?
                </LexButton>
            </Box>
        </Box>)
};


export default ContextCollector;