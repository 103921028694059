import React from 'react';
import AutomataExpandableDescription from '../AutomataExpandableDescription';
import AutomataGenericButtons from '../AutomataGenericButtons';



const AutomataStepConfiguraton = ({ options, legacy, stepOperations, collectorComponent }) => {
    return (
        <div className="stepConfigContainer"
            style={{
                '--total-padding': '20px',
                '--header-padding': '5px',
            }}>
            {options?.useExpandableDescription ? <AutomataExpandableDescription options={options} /> : null}
            {legacy ?
                <>
                    {options?.collectorComponent ? options.collectorComponent(stepOperations) : null}
                </> :
                <>
                    {collectorComponent ? collectorComponent(stepOperations) : null}
                </>}

            {options?.buttonOpts ? <AutomataGenericButtons buttonOpts={options.buttonOpts} stepOperations={stepOperations} /> : null}
        </div >
    );
};


export default AutomataStepConfiguraton;