import Collector from "../../Common/Collector";
import { quickInput } from "../../Common/utilities";

const GenerativeSeqCollector = ({ configureStep, stepData, errorDisplay, stepId,testState, macrosObject,prevState, availableVariables, ...other }) => {
    let inputFields = [
        quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in."),
        quickInput(stepData, configureStep, "Enumeration Label (Optional)", "enumLabel", "This is the label that will be applied to the enumeration."),
        quickInput(stepData, configureStep, "Starting Index", "startingIndex", "This is the starting index for the sequence"),
        quickInput(stepData, configureStep, "Ending Index", "endingIndex", "This is the ending index for the sequence"),
        quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM", true, 8, true, { ...macrosObject, ...prevState }, availableVariables)
    ]
    if (stepData?.useExistingContext && stepData?.sequencePrior) {
        inputFields = [
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in."),
            quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM", true, 8, true, { ...macrosObject, ...prevState }, availableVariables)
        ]
    }
    return <Collector
        stepId={stepId}
        inputFields={inputFields}
    />
};

export default GenerativeSeqCollector;
