import React, { useEffect, useState, useContext } from "react";
import {
    Box, Modal,
    Typography,
    Button,
} from '@mui/material';
import APIContext from "../../contexts/api/context";

function BackButtonModal(props) {
    const {api} = useContext(APIContext)
    const [redosRemaining, setRedosRemaining] = useState(31)
    const handleClose = () => {
        props.backButtonHit()
        props.setBackModalOpen(false)
    };
    useEffect(() => {
        api('/ptntst/getRedosRemaining', { patentId: props.patentId }).then(res => {
            setRedosRemaining(res.data.redosRemaining)
        })
    }, [props.backModalOpen])
    return (

        <Modal
            open={props.backModalOpen}
            onClose={() => { props.setBackModalOpen(false) }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: 2,
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Return to the previous page?
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Due to the token cost of text generaton set by our partners, we restrict the number of times you may utilize the back button. For this patent, you have {redosRemaining - 1} usages remaining.
                </Typography>
                <div style={{ marginTop: "20px" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleClose}>Confirm</Button>
                </div>
            </Box>
        </Modal>
    );
}

export default BackButtonModal;