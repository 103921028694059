import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Box, CircularProgress, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const LexHTMLDisplay = ({ open, onClose, htmlString,documentName,documentAuthor}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const contentRef = useRef(null);


  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>
        Document Viewer ({documentName})
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={contentRef} dividers style={{ height: '80vh', overflowY: 'auto' }}>
                <iframe 
                    style={{ width: "100%", height: "100%" }}
                    sandbox="allow-same-origin allow-forms allow-pointer-lock allow-popups"
                    srcDoc={htmlString}
                />
      </DialogContent>
    </Dialog>
  );
};

export default LexHTMLDisplay;