import { useContext, useRef, useState, useEffect } from "react";

export const useLLMControls = (saveWorkflow) => {

    const [model, setModel] = useState("GPT-4o");
    const LLMOptions = ["Gemini", "GPT-4o", 'Claude Sonnet 3.5', 'Llama 3.1', 'Nvidia Nemotron']
    const handleLLMChange = (e) => {
        setModel(e.target.value)
        saveWorkflow(100)
    }
    return { LLMControlsOps:{model, LLMOptions, currentLLM: model, handleLLMChange}, LLMControlsOther:{model,setModel}}

}