
import React from 'react';
import { MarkdownTable } from '../../../Lex/LexMarkdownTable';
import {Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import SettingsIcon from '@mui/icons-material/Settings';


const OutputDisplaySingle = ({ fixHeight,editText, markdown, varName, stepId }) => {
    return (
        <Box>
            <MarkdownTable
                height={fixHeight?fixHeight:'auto'}
                markdown={markdown} />
            <Box
                style={{
                    position: "relative",
                    height: "60px"
                }}>
                <Box
                    style={{position: "absolute", bottom: 0,right: 0}}
                    sx={{
                        border: 1,
                        borderRadius: 3,
                        p: 1,
                    }}>
                    <Tooltip title="Edit Output">
                        <IconButton
                            onClick={() => editText(varName, stepId)}>
                            <SettingsIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

export default OutputDisplaySingle;