import React, { useState } from "react";
import { Box, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import Settings from "./Settings";
import Files from "./Files";
import LexTabMenu from "../../../Lex/LexTabMenu";
import Macros from "./Macros";
import Workspaces from "./Workspaces";

const AutomataSidebar = (props) => {
    const [value, setValue] = useState(0);
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const outerSx = {
        width: isMobile ? '80vw' : '430px',
        maxWidth: isMobile ? 'auto' : '430px',
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100vh',
        height: "100vh",
        bgcolor: 'background.sidebar',
        borderLeft: 1,
        borderColor: "action.disabled",
    }
    const changeHandler = (e, newValue) => { setValue(newValue) }
    return (
        <Box sx={outerSx}>
            <LexTabMenu
                tabsSx={{ bgcolor: "sidebarTabs.tabBackgroundColor" }}
                tabSx={{ color: "sidebarTabs.tabTextColor" }}
                optionList={[
                    { label: "Settings" },
                    { label: "Files" },
                    { label: "Workspaces" },
                    { label: "Macros" }]}
                sidebarType={value}
                setSidebarType={setValue}
                changeHandler={changeHandler}
            />
            {value === 0 ? <Settings /> : null}
            {value === 1 ? <Files stepId={props.stepId} /> : null}
            {value === 2 ? <Workspaces /> : null}
            {value === 3 ? <Macros /> : null}
        </Box>
    )
}

export default AutomataSidebar;