import { useState,useEffect} from "react";

function convertMacrosToObject(macrosArray) {
    return macrosArray.reduce((acc, macro) => {
        if (macro.name && macro.value) {  // Only include entries with both name and value
            acc[macro.name] = macro.value;
        }
        return acc;
    }, {});
}



export const useMacros=(api)=>{
    const [remoteMacros, setRemoteMacros] = useState([]);
    const macrosObject = convertMacrosToObject(remoteMacros);
    const updateMacrosRemote = (macros) => {
        setRemoteMacros(macros)
        api('/ptntst/updateMacros', { macros: macros }).then(res => { })
    }

    const getMacrosRemote = (setMacros) => {
        api('/ptntst/getMacros', {}).then(res => {
            setRemoteMacros(res.data.macros)
            setMacros(res.data.macros)
        })
    }
    useEffect(() => {
        getMacrosRemote(() => { })
    }, [])
    return {
        getMacrosRemote, remoteMacros, updateMacrosRemote,macrosObject}

}