import Collector from '../../Common/Collector';
import { quickCheck, quickInput } from '../../Common/utilities';

const GenerativeStepCollector = ({ configureStep, stepData, errorDisplay, stepId, testState, macrosObject,prevState, availableVariables, ...other }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.", false, 1),
            quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM", true, 8, true, { ...macrosObject, ...prevState }, availableVariables)
        ]}
        checkboxFields={[
            quickCheck(stepData, configureStep, "Use Multiparse?", "useMultiParse", "Add me"),
            // quickCheck(stepData, configureStep, "Generate a Table?", "isTable", "Add me"),
            quickCheck(stepData, configureStep, "Use Citations", "useCitations", "Add me"),
        ]}
    />
};

export default GenerativeStepCollector;