import { useState,useEffect} from "react";

export const useWorkspaces=(
    api,
    activeWorkflow,
    activeTestProfile,
    activeTestProfileId,
    refresh)=>{
    const [refreshExecutionIds, setRefreshExecutionIds] = useState(false);
    const [executionIds, setExecutionIds] = useState([]);
    const [activeExecutionName, setActiveExecutionName] = useState(null);
    const [activeExecutionActualName, setActiveExecutionActualName] = useState(null);
    const [activeExecutionId, setActiveExecutionId] = useState(null);


    const updateExecutionName = (force=false,execIds=executionIds)=>{
        let executionName = execIds.find((exId) => exId.flowExecutionId === activeExecutionId)
        if (executionName || force) {
            setActiveExecutionName(executionName?.name)
            setActiveExecutionActualName(executionName?.actualName)
        }
    }
    useEffect(updateExecutionName,[activeExecutionId])

    useEffect(() => {
        if (refreshExecutionIds) {
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                const executionIdExists = res.data.flowExecutionNames.some(exId => exId.flowExecutionId === activeExecutionId);
                if (!executionIdExists) {
                    let defaultExecution = res.data.flowExecutionNames.find((exId) => exId.flowExecutionId === activeTestProfileId);
                    setActiveExecutionId(defaultExecution?.flowExecutionId);
                } else {
                    updateExecutionName(true)
                }
                refresh()

            });
            setRefreshExecutionIds(false)
        }
    }, [activeTestProfile])


    useEffect(() => {
        setRefreshExecutionIds(true)
    }, [activeWorkflow])

    const createWorkspace = () => {
        api('/ptntst/createWorkspace', { flowId: activeWorkflow }).then(res => {
            setActiveExecutionId(res.data.executionId)
            const executionId = res.data.executionId
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                updateExecutionName(true,res.data.flowExecutionNames)
                setActiveExecutionId(executionId)
                refresh()
            });
        });
    }
    const renameWorkspace = (newName) => {
        api('/ptntst/renameWorkspace', { executionId: activeExecutionId, name: newName }).then(res => {
            api('/ptntst/getWorkspaces', { flowId: activeWorkflow, formatted: true }).then(res => {
                setExecutionIds(res.data.flowExecutionNames)
                let execution = res.data.flowExecutionNames.find((exId) => exId?.name === newName + ' (' + exId.flowExecutionId + ')')
                setActiveExecutionName(execution?.name)
                setActiveExecutionActualName(execution?.actualName)
                refresh()
            });
        })
    }

    const changeWorkspace = (e) => {
        const workspaceName = e.target.value
        executionIds.forEach((exId) => {
            if (exId.name === workspaceName) {
                setActiveExecutionId(exId.flowExecutionId)
                refresh()
            }
        })
    }

    return {workspaceOps:{createWorkspace, renameWorkspace, activeExecutionActualName, activeExecutionId, executionIds, changeWorkspace, activeExecutionName},workspaceOther:{activeExecutionId:activeExecutionId}}

}