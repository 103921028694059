import { useState, useEffect } from "react";

export const usePlayAndFF = (api, setIsRunning, updateTestProfile, actionPackager, saveWorkflow) => {

    // Create an empty step object
    const fastForwardStep = (index) => {
        saveWorkflow()
        setIsRunning(true)
        const reqPkg = actionPackager(index)
        api('/ptntst/testExecution', reqPkg).then(res => {
            updateTestProfile(res.data.history)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    // Delete step data and update dependent steps
    const playStep = (index) => {
        saveWorkflow()
        setIsRunning(true)
        const reqPkg = actionPackager(index)
        api('/ptntst/debugExecution', reqPkg).then(res => {
            updateTestProfile(res.data.history)
        }).catch((e) => {
            console.log(e)
            setIsRunning(false)
        })
    }

    return { playStep, fastForwardStep }

}