
import React, { useEffect, useState, useRef } from 'react';
import { MarkdownTable } from '../../../Lex/LexMarkdownTable';
import OutputSideMenu from '../../Common/OutputSideMenu';
import useHiddenState from '../../Common/hooks/useHiddenState';


const IDITestDisplay = ({ configureStep, stepData, errorDisplay, testState, stepId }) => {
    const {retrieveHiddenState,embedHiddenState} = useHiddenState(testState,configureStep,stepId,2)
    const log = retrieveHiddenState('log', "")
    const [mode, setMode] = useState('log');
    const outerRef = useRef(null);
    const contentRef = useRef(null);
    const [maxWidth, setMaxWidth] = useState('100%'); // Default max width

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (outerRef.current && contentRef.current) {
                const outerWidth = outerRef.current.offsetWidth;
                const sidebarWidth = 150 + 10; // Sidebar width + margin
                const availableWidth = outerWidth - sidebarWidth;

                // Adjust maxWidth to prevent overflow
                if (availableWidth > 0) {
                    setMaxWidth(`${availableWidth}px`);
                }
            }
        });

        if (outerRef.current) {
            observer.observe(outerRef.current);
        }

        return () => {
            if (outerRef.current) {
                observer.unobserve(outerRef.current);
            }
        };
    }, []);

    return (
        <div style={{ width: "100%", display: 'flex', flexDirection: 'row' }} ref={outerRef}>
            <OutputSideMenu
                mode={mode}
                setMode={setMode}
                options={[{ mode: 'log', text: 'Log' }, { mode: 'output', text: 'Raw Output' }]} />
            <div
                ref={contentRef}
                style={{
                    flexShrink: 1,
                    height: '500px',
                    maxWidth: "calc(100% - 160px)",

                }}
            >
                {mode === 'log' && <MarkdownTable whiteSpace={'pre-wrap'} markdown={log} />}
                {mode === 'output' && (
                    <MarkdownTable whiteSpace={'pre-wrap'} markdown={testState?.[stepData?.varName] ? testState?.[stepData?.varName] : ""} />
                )}
            </div>
        </div>
    );
};

export default IDITestDisplay;