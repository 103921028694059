import React, { useContext, useRef } from 'react';
import DesContext from '../../../../../contexts/singleFlow/context';
import AddSharpIcon from '@mui/icons-material/AddSharp';
import { Box, Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';

//#TODO:CSS-CLEAN-UP
const StepDivider = ({ index }) => {
    const { manager } = useContext(DesContext);
    const buttonRef = useRef(null)

    const handlePlusClick = (event) => {
        manager.addStep(index + 1)
    };

    return (
        <Box style={{
            width: "90%",
            display: "flex",
            margin: "1px"
        }}>
            <Box style={{
                width: "40%",
                marginTop: "12px"
            }}>
                <Divider />
            </Box>
            <Box ref={buttonRef} style={{ width: "16px" }}>
                <IconButton
                    onClick={handlePlusClick}
                    size="small"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    style={{
                        marginLeft: '-3px',
                        width: '22px',
                        height: '22px',
                        color: 'inherit',
                        clipPath: "polygon(50% 0, 99% 50%, 50% 99%, 0 50%)"
                    }}>
                    <AddSharpIcon
                        sx={{ width: '17px' }} />
                </IconButton>
            </Box>
            <Box style={{
                width: "40%",
                marginTop: "12px"
            }}>
                <Divider />
            </Box>
        </Box>
    );
}

export default StepDivider;