export const useStepMutations = (
    activeTestProfile,
    activeTestProfileId,
    macrosObject,
    setStepData,
    setActiveTestProfile,
    steps,
    stepDataOuter
) => {

    const createStepOperations = ({ stepData, stepIndex, stepId }) => {
        let availableVariables = {...macrosObject}
        for (let i = 0; i < stepIndex; i++) {
            let step = steps[i]
            if (stepDataOuter[step]?.varName) {
                availableVariables[stepDataOuter[step].varName] = "Declared at step " + (i+1)+"."
            }
            if (stepDataOuter[step]?.fileVarName) {
                availableVariables[stepDataOuter[step].fileVarName] ="Declared at step " + (i+1)+"."
            }
            if (stepDataOuter[step]?.name === "formDataStep" && stepDataOuter[step]?.collectedFields && stepDataOuter[step]?.collectedFields.length > 0) {
                stepDataOuter[step].collectedFields.forEach((field) => {
                    availableVariables[field.varName] = "Declared at step " + (i+1)+"."
                })
            }
        }
        return {
            stepData,
            stepIndex,
            stepId,
            activeTestProfileId,
            availableVariables,
            prevState: activeTestProfile?.[stepIndex > 0 ? stepIndex - 1 : 0]?.state,
            testState: activeTestProfile?.[stepIndex]?.state,
            macrosObject,
            configureStep: {
                set: (data) => {
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        newData[stepId] = { ...newData[stepId], ...data };
                        return newData;
                    });
                },
                deleteSetKey: (key) => {
                    setStepData(prevData => {
                        let newData = { ...prevData };
                        delete newData[stepId][key];
                        return newData;
                    });
                },
                deleteSetTestKey: (key) => {
                    setActiveTestProfile(prevActiveTestProfile => {
                        let newActiveTestProfile = [...prevActiveTestProfile];
                        delete newActiveTestProfile[stepIndex].state[key]
                        return newActiveTestProfile;
                    });
                },
                setTest: (data) => {
                    setActiveTestProfile(prevActiveTestProfile => {
                        let newActiveTestProfile = [...prevActiveTestProfile];
                        newActiveTestProfile[stepIndex].state = { ...newActiveTestProfile[stepIndex].state, ...data };
                        return newActiveTestProfile;
                    });
                },
                priorConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && stepDataOuter?.[stepWrap[0]]?.name === "generativeStep"
                    })
                    return validPriorConversations;
                },
                priorSeqConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && stepDataOuter?.[stepWrap[0]]?.name === "generativeSequence"
                    })

                    return validPriorConversations;
                },
                priorSeqStepConversations: () => {
                    let validPriorConversations = steps.map((step, index) => [step, index]).filter((stepWrap, idx) => {
                        return idx < stepIndex && (stepDataOuter?.[stepWrap[0]]?.name === "generativeSequence" || stepDataOuter?.[stepWrap[0]]?.name === "generativeStep")
                    })
                    return validPriorConversations;
                },

            },

            errorDisplay: ({ blah }) => {

            }
        }
    }
    const createStepOperationsById = (stepId) => {
        let stepIndex = steps.indexOf(stepId)
        return createStepOperations({ stepData: stepDataOuter[stepId], stepIndex, stepId })
    }


    return { createStepOperations, createStepOperationsById }

}