import React, { useState } from 'react';
import { Tabs, Tab } from '@mui/material';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const LexDynamicTabMenu = ({
    style,
    tabStyle,
    tabOptions,
    tabSelected,
    handleTabChange,keyData }) => {
    const tabSelectedIndex = tabOptions.findIndex(obj => obj.name === tabSelected.name);
    const handleChange = (event, newValue) => {
        handleTabChange(tabOptions[newValue]);
    };
    return (<Tabs
        style={style}
        TabIndicatorProps={{ sx: { bgcolor: "primary.children" } }}
        value={tabSelectedIndex}
        onChange={handleChange}
        aria-label="responsive tabs example"
    >
        {tabOptions.map((tab, index) => (
            <Tab key={keyData+index+"k42"} sx={tabStyle} label={tab.label} {...a11yProps(index)} />
        ))
        }
    </Tabs>
    )
}

export default LexDynamicTabMenu;