import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import APIContext from "../../../contexts/api/context";
import { photos } from "./photos";

const pk = ['production'].includes(process.env.NODE_ENV)
  ? "pk_live_51GXKg8Bwq0cR1CQY7eSuGU7BCPwjUshGgJfC05stdpAGMBAI6RQjTPnAO5Hv8wRivP6aAIKz54PWFWbWqjg0VJzK00U86YhDdC" : "pk_test_51PUrXuDfMfNR2jqIgx25nPbJQqxraU3TofbEEmM6XfrIt8Uts9boZHk2dNaXQiU5T8IccJVt5aVwIbjwn4YF0Idn00FaFH9H7V"
const stripePromise = loadStripe(pk);

export const CheckoutForm = () => {
  const {api} = useContext(APIContext)
  const [isMobile, setIsMobile] = useState(window.matchMedia('(max-width: 768px)').matches);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleResize = () => setIsMobile(mediaQuery.matches);

    mediaQuery.addEventListener('change', handleResize);
    return () => mediaQuery.removeEventListener('change', handleResize);
  }, []);

  const [clientSecret, setClientSecret] = useState('');
  const [currentPhoto, setCurrentPhoto] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentPhoto((x) => ((x + 1) % (photos.length - 1)));
    }, 5000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // Create a Checkout Session as soon as the page loads
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const patentId = urlParams.get('patent_id');
    const onetime = urlParams.get('onetime');
    const review = urlParams.get('review');
    api('/ptntst/create-checkout-session',
      { patentId, onetime, review, origin: window.location.origin })
      .then(apiResponse => {
        setClientSecret(apiResponse.data.clientSecret)
      })
  }, []);

  if (isMobile) {
    return (<div id="checkout" style={{
      flex: '1 0 fit-content',
      overflow: 'hidden scroll',
      scrollbarGutter: 'stable both-edges',
      height: '100%',
      background: 'rgb(43, 43, 43)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    }}>
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>)
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      background: 'rgb(43, 43, 43)',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      overflow: 'hidden',
      // padding: '1cm',
    }}>

      <div style={{
        flex: '1 1 auto',
        overflow: 'hidden',
        height: '100vh',
        width: 'fit-content',
        // width:"10%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'right',
      }}>
        {photos.map((photo, index) => (
          <div key={photo} style={{
            flex: '0 1 fit-content',
            backgroundImage: `url(${photo})`,
            backgroundSize: 'cover',
            backgroundPosition: '80% 50%',
            opacity: index === currentPhoto ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            width: '45%',
            height: '100%',
            position: 'absolute',
            left: 0,
          }} />))}
      </div>

      <div id="checkout" style={{
        flex: '1 1 fit-content',
        overflow: 'hidden scroll',
        scrollbarGutter: 'stable both-edges',
        height: '100vh',
        width: 'fit-content',
        // width:"10%",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'right',
        padding: '0px 1cm',
      }}>
        {clientSecret && (
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        )}
      </div>
    </div>
  )
}
