export const useStepModifiers = (useMockDialog, flowStateController, saveWorkflow) => {

    // Create an empty step object
    const emptyStep = () => ({ initialized: false, display: "overview", title: "", files: [] });

    // Delete step data and update dependent steps
    const deleteStepData = (delKey) => () => {
        flowStateController.setStepData((prevData) => {
            const newData = { ...prevData };
            delete newData[delKey];
            // Update step data for dependent steps
            for (const key in newData) {
                if (newData[key]?.useExistingContext && newData[key]?.contextToContinue === delKey) {
                    newData[key].useExistingContext = false;
                    delete newData[key].contextToContinue;
                }
            }
            return newData;
        });

        saveWorkflow(200);
    };

    // Add a new step to the workflow
    const addStep = (index) => {
        const newId = Math.random().toString(36).substring(7);
        flowStateController.setSteps((prevSteps) => {
            // Add new step to stepData
            flowStateController.setStepData((prevData) => ({
                ...prevData,
                [newId]: emptyStep(),
            }));
            // Add the new step to steps
            const isAppending = index > prevSteps.length - 1;
            return isAppending
                ? [...prevSteps, newId]
                : [...prevSteps.slice(0, index), newId, ...prevSteps.slice(index)];
        });

        flowStateController.setActiveTestProfile((prevActiveTestProfile) => {
            const stepTestData = { stepId: newId, state: {} };
            // Add the step to the active test profile
            const isAppending = index > prevActiveTestProfile.length - 1;
            return isAppending
                ? [...prevActiveTestProfile, stepTestData]
                : [
                    ...prevActiveTestProfile.slice(0, index),
                    stepTestData,
                    ...prevActiveTestProfile.slice(index),
                ];
        });
    };

    // Remove a step from the workflow
    const removeStep = useMockDialog(
        "Are you sure you wish to delete this step? After you take this action, the step cannot be recovered.",
        "Delete Step",
        (index) => {
            const delKey = flowStateController.steps[index];

            flowStateController.setSteps((prevSteps) => {
                const newSteps = [...prevSteps.slice(0, index), ...prevSteps.slice(index + 1)];
                setTimeout(deleteStepData(delKey), 200);
                return newSteps;
            });
            flowStateController.setActiveTestProfile((prevActiveTestProfile) =>
                [...prevActiveTestProfile.slice(0, index), ...prevActiveTestProfile.slice(index + 1)]
            );
        },
        "singleWorkflow",
        "deleteStep",
        true
    );

    return { addStep, removeStep }

}