
import React, { useEffect, useState } from 'react';
import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import CheckboxCollector from './common/checkboxCollector';
import GenerativeStepCollector from '../../../components/Automata/GenerativeStep/GenerativeStepCollector';
import ContextCollector from '../../../components/Automata/Common/ContextCollector';
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import GenerativeStepVerificationCollector from '../../../components/Automata/GenerativeStep/GenerativeStepVerificationCollector';
import GenerativeStepTestDisplay from '../../../components/Automata/GenerativeStep/GenerativeStepTestDisplay';
import LexTypography from '../../../components/Lex/LexTypography';
import { Box } from '@mui/material';

export const generativeStep = {
    stepMenuOptions: ['writePrompt', 'stepMetaData', 'outputDisplay', 'validation'],
    initializedStep: 'writePrompt',
    execution: (props) =>
        <Box sx={{ padding: 2, border: 1, borderRadius:3, borderColor: 'action.disabled', marginTop: 3 }}>
            <LexTypography sx={{ paddingBottom: 2 }} variant="h6">Generative Output</LexTypography>
            <GenerativeStepTestDisplay fixHeight={"500px"} {...props} />
        </Box>,
    continueCondition: ({ stepData, testState }) => testState?.[stepData.varName] ? true : false,
    executionSingleStepTextNoContinue: 'Generate',
    executionSingleStepText: 'Regenerate',
    overview: {
        name: 'generate',
        label: 'Produce LLM generated text?',
        sublabel: 'Utilize GPT-4o, Gemini, Claude or another LLM to generate legal text.',
        icon: (<div style={{ position: 'relative', width: '100%', height: '100%', marginTop: '10px', marginLeft: '4px' }}>
            <AutoAwesomeSharpIcon style={{
                position: 'absolute', height: '16px', width: '16px',
                top: '4px', left: '16px',
                clipPath: 'polygon(0 0, 50% 0, 100% 100%, 0 100%)',
                transform: 'rotate(180deg)',
            }} />
            <TextFieldsOutlinedIcon style={{
                position: 'absolute',
                top: '5px',
                left: '0px',
            }} />
        </div>),
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'generativeStep',
                labelDisplay: "Generation",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        label: 'Test Output',
        enabled: true,
        collectorComponent: props => <GenerativeStepTestDisplay {...props} />

    },
    // Please analyze the diffences between the responses offered by the two different models.
    primaryOptions: {
        header: 'Generative Step',
        useExpandableDescription: true,
        description: 'A generative step creates a conservation with the LLM. You may either create a fresh conversation or continue an existing one.  \n\n Under certain cirmcumstances, continuing an existing conversation allows the LLM to utilize the existing context to generate more accurate responses.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    'verification_critera': 'Please analyze the diffences between the responses offered by the two different models.',
                })
                configureStep.set({
                    useExistingContext: false,
                    display: 'tableCheck'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    'verification_critera': 'Please analyze the diffences between the responses offered by the two different models.',
                })
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        useExpandableDescription: true,
        header: 'Generative Step',
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollector nextStep={'tableCheck'} {...props} />,
    },
    validation: {
        label: 'Validation',
        show: (stepData) => { return stepData?.useMultiParse },
        collectorComponent: (props) => <GenerativeStepVerificationCollector {...props} />,
    },
    tableCheck: {
        useExpandableDescription: true,
        header: 'Are you generating a table?',
        description: 'We can help you generate a table in a variety of formats.  Please select the checkbox below.',
        collectorComponent: (props) => <CheckboxCollector
            paramName={'isTable'}
            checkboxLabel={'Generate a table?'}
            nextStep={'writePrompt'}
            {...props} />
    },

    writePrompt: {
        useExpandableDescription: true,
        label: 'Step Configuration',
        header: 'Generative Step',
        description: 'Please write your LLM prompt and assign an output variable. You may use any text variables previously set at a preceeding step.',
        collectorComponent: (props) => <GenerativeStepCollector {...props} />,
    },
    default: {

    }


};