import React from "react";
import { Tab, Tabs, Box } from '@mui/material';

const LexTabMenu = ({ optionList, tabSx, tabsSx, sidebarType, setSidebarType, changeHandler }) => {
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };
    return (
        <Box sx={{ borderBottom: 1, borderColor: 'action.disabled' }}>
            <Tabs
                sx={tabsSx}
                value={sidebarType}
                onChange={changeHandler}
                aria-label="basic tabs example">
                {optionList.map((option, index) => {
                    return <Tab
                        key={option.label+index+"k1"}
                        sx={tabSx}
                        label={option.label}
                        {...a11yProps(index)} />
                })}
            </Tabs>
        </Box>)
};

export default LexTabMenu;