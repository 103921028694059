import React, { useContext } from 'react';
import DesContext from '../../../../../contexts/singleFlow/context';
import IconButton from '@mui/material/IconButton';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Box } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';

const FastForwardControl = ({ index }) => {
    const { manager } = useContext(DesContext);
    return (
        <Box
            display="flex"
            sx={{
                borderRadius: 1,
                border: 1,
                borderColor: 'action.disabled',
                margin: 1
            }}
        >
            <IconButton onClick={(e) => { manager.playStep(index) }}>
                <PlayArrowIcon />
            </IconButton>
            <IconButton onClick={(e) => { manager.fastForwardStep(index) }}>
                <FastForwardIcon />
            </IconButton>
        </Box>
    );
}

export default FastForwardControl;