
import React, { useEffect, useState,useContext } from 'react';
import { MarkdownTable } from '../../../Lex/LexMarkdownTable';
import { Box } from '@mui/material';
import LexTypography from '../../../Lex/LexTypography';
import LexBox from '../../../Lex/LexBox';
import OutputTable from '../../Common/OutputTable';
import OutputSideMenu from '../../Common/OutputSideMenu';
import useArrayCheckboxHiddenState from '../../Common/hooks/useArrayCheckboxHiddenState';
import useHiddenState from '../../Common/hooks/useHiddenState';
import DesContext from '../../../../contexts/singleFlow/context';

const APITestDisplay = ({ fixHeight,configureStep, stepData, errorDisplay, testState, stepId }) => {
    const {retrieveHiddenState,embedHiddenState} = useHiddenState(testState,configureStep,stepId,2)
    let { manager } = useContext(DesContext);
    const utilizedOptions = retrieveHiddenState('utilized', [])
    const metadataKeys = retrieveHiddenState('metadataKey', [])
    const {selectedOptions, handleCheckboxChange, possibleOptions} = useArrayCheckboxHiddenState(
        testState,
        configureStep,
        stepId,
        'searchResults',
        'incomingChecked')
    const [mode, setMode] = useState('search')

    const openExternalFile = (option) => {
        manager.showExternalFile(option['dd_link'], option['file_ext'])
    }

    return (
        <Box display="flex" sx={{pt:2,height: fixHeight ? fixHeight : null, overflowY: fixHeight ? "scroll" : null,width: "100%"  }}> 
            {mode === 'search' ?
                <Box sx={{pl:4}} style={{overflowX: 'auto',width:"100%" }}>
                    <LexTypography
                        variant='h6'
                        style={{ paddingBottom: "10px" }}>
                        Search Results
                    </LexTypography>
                    {possibleOptions.length === 0 ?
                        <LexTypography variant='body1'>
                            No search results found/Step has not been executed.
                        </LexTypography> : null}
                    {possibleOptions.length > 0 && metadataKeys.length > 0 ?
                        <LexTypography variant='body1'>
                            After any changes to your selections, you must re-run this step.
                        </LexTypography> : null}
                    {possibleOptions.length > 0 && metadataKeys.length > 0 ?
                        <OutputTable
                        openExternalFile={openExternalFile}
                            metadataKeys={metadataKeys}
                            utilizedOptions={utilizedOptions}
                            selectedOptions={selectedOptions}
                            possibleOptions={possibleOptions}
                            handleCheckboxChange={handleCheckboxChange} /> : null}
                </Box> : null}
        </Box>
    );
};

export default APITestDisplay;