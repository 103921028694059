
import React from 'react';
import { List,ListItemText, Box, ListItemButton } from '@mui/material';

const OutputSideMenu = ({ mode,setMode,options }) => {
    return (
        <Box sx={{bgcolor:'action.hover',paddingTop:1, borderRadius:4, width: "180px", marginRight: "10px" }}>
            <List>
                {options.map(option => (<ListItemButton
                    button
                    key={"outputSideMenu_"+option.mode}
                    selected={mode === option.mode}
                    onClick={() => setMode(option.mode)}
                >
                    <ListItemText primary={option.text}/>
                </ListItemButton>))}
            </List>
        </Box>
    );
};

export default OutputSideMenu;