import AddCommentIcon from '@mui/icons-material/AddComment';
import TextsmsIcon from '@mui/icons-material/Textsms';
import ContextCollectorSeq from '../../../components/Automata/Common/ContextCollectorSeq';
import GenerativeSeqCollector from '../../../components/Automata/GenerativeSeq/GenerativeSeqCollector';
import AutoModeSharpIcon from '@mui/icons-material/AutoModeSharp';
import GenerativeSeqTestDisplay from '../../../components/Automata/GenerativeSeq/GenerativeSeqTestDisplay';
import { Box } from '@mui/material';
import LexTypography from '../../../components/Lex/LexTypography';

export const generativeSequence = {
    stepMenuOptions: ['writePrompt', 'stepMetaData', 'outputDisplay'],
    initializedStep: 'writePrompt',
    execution: (props) =>
        <Box sx={{ bgcolor: "background.paper", padding: 2, border: 1, borderRadius: 3, borderColor: 'action.disabled', marginTop: 3 }}>
            <LexTypography sx={{ paddingBottom: 3 }} variant="h5">Generative Output</LexTypography>
            <GenerativeSeqTestDisplay fixHeight={'500px'}  {...props} />
        </Box>,
    continueCondition: ({ stepData, testState }) => testState?.[stepData.varName] ? true : false,
    executionSingleStepTextNoContinue: 'Generate',
    executionSingleStepText: 'Regenerate',
    overview: {
        name: 'generateSequence',
        label: 'Utilize an LLM to produce sequenced data.',
        sublabel: 'Utilize GPT-4o, Gemini, Claude or another LLM to generate sequenced legal text.',
        icon: <AutoModeSharpIcon />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                name: 'generativeSequence',
                labelDisplay: "Seq. Gen",
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    outputDisplay: {
        label: 'Test Output',
        enabled: true,
        collectorComponent: (props) => {
            return <GenerativeSeqTestDisplay {...props} />
        }
    },
    primaryOptions: {
        header: 'Sequence Step',
        useExpandableDescription: true,
        description: 'Using a sequence step, you can create a sequence of generated texts, with each element of the sequence reprsenting a portion of a sequential process.',
        buttonOpts: [{
            label: 'Start a new conversation?',
            sublabel: '',
            icon: <AddCommentIcon />,

            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: false,
                    display: 'writePrompt'
                })
            }
        },
        {
            label: 'Continue an existing conversation?',
            sublabel: '',
            icon: <TextsmsIcon />,
            disabled: ({ configureStep, stepData, errorDisplay }) => {
                return configureStep.priorSeqStepConversations().length === 0
            },
            click: ({ configureStep, stepData, errorDisplay }) => {
                configureStep.set({
                    useExistingContext: true,
                    display: 'selectPreviousConversation'
                })
            }
        }


        ]
    },
    selectPreviousConversation: {
        header: 'Sequence Step',
        useExpandableDescription: true,
        description: 'Please select the conversation you wish to continue.',
        collectorComponent: (props) => <ContextCollectorSeq nextStep={'writePrompt'} {...props} />,
        click: ({ configureStep, stepData, errorDisplay }) => {
            configureStep.set({
                display: 'writePrompt'
            })
        },
    },
    writePrompt: {
        label: 'Step Configuration',
        useExpandableDescription: true,
        header: 'Sequence Step',
        description: 'You may write a sequential LLM prompt. \n\nPlease note, these prompts will be run in parallel, and each prompt will differ only from the rest by the use of implicit $step variable. The $step variable represents an index in the generated sequence.\n\n',
        collectorComponent: (props) => <GenerativeSeqCollector {...props} />,
    },
    default: {

    }


};