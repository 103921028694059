import {useState} from "react";

export const useStaticFiles=(
    activeWorkflow, 
    api,
    apiWithLoading, 
    refresh)=>{

    const [staticFiles, setStaticFiles] = useState([])
    const uploadStaticFiles = async (acceptedFiles) => {
        for (var i = 0; i < acceptedFiles.length; i++) {
            let acceptedFile = acceptedFiles[i];
            let formData = new FormData();
            formData.append('file', acceptedFile);
            formData.append('flowId', activeWorkflow);
            apiWithLoading('/ptntst/uploadStaticFile', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                refresh()}).catch(e=>console.log(e))
        }

    }
    const loadStaticFiles = (files)=>{
        setStaticFiles(files ? files : [])
    }
    const deleteStaticFile = async (fileIndex, stepId) => {
        const formData = new FormData();
        const fileData = staticFiles[fileIndex]
        formData.append('flowId', activeWorkflow);
        formData.append('fileData', JSON.stringify(fileData));
        formData.append('fileIndex', fileIndex);
        apiWithLoading('/ptntst/deleteStaticFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            refresh()
        }).catch(e=>console.log(e));
    };

    return {staticFileOps: {staticFiles, uploadStaticFiles, deleteStaticFile}, staticFilesOther:{loadStaticFiles}}

}