import { useContext } from 'react';
import LexFileDropzone from '../../../Lex/LexFileDropzone';
import DesContext from '../../../../contexts/singleFlow/context';
import { Box, Typography } from '@mui/material';

const DocGenUploadCollector = ({ configureStep, stepData, errorDisplay, stepIndex, stepId,testState }) => {
    let { manager } = useContext(DesContext);
    const files = stepData?.template ? [stepData?.template] : []
    const mode = 'template'
    const delFn = manager.deleteFile(mode)
    return (
        <Box>
            {stepData.template ?
                <Typography
                    sx={{
                        fontWeight: "500"
                    }}
                    variant="body1">
                    Currently utilizing {stepData.template?.origName}
                </Typography> : null}
            <LexFileDropzone
                files={files}
                uploadFuncton={manager.uploadFiles({ configureStep, stepData, stepIndex, stepId, mode })}
                dependencies={[configureStep, stepData]}
                deleteFunction={(index) => () => delFn({ configureStep, stepData, testState, files, stepIndex, stepId, file_idx: index })}
                acceptedFiles={{'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx']}}
            />
        </Box>)
}

export default DocGenUploadCollector;