export const embedHiddenStatePre = (testState,configureStep,stepId,mode) => (hiddenKey, hiddenValue) => {
    if (mode === 0) {
        configureStep.setTest({
            [`${stepId}_${hiddenKey}`]: hiddenValue
        })
        testState[`${stepId}_${hiddenKey}`] = hiddenValue;
    } else if (mode === 1) {
        configureStep.setTest({
            [`__${stepId}__${hiddenKey}`]: hiddenValue
        })
        testState[`__${stepId}__${hiddenKey}`] = hiddenValue;
    } else if (mode === 2) {
        let existing_hidden
        if (!testState[stepId]) {
            existing_hidden = {};
        } else {
            existing_hidden = testState[stepId];
        }
        configureStep.setTest({
            [stepId]: {...existing_hidden, [`__${hiddenKey}`]: hiddenValue}
        })
    }
}

export const deleteHiddenStatePre = (testState,configureStep,stepId,mode) => (hiddenKey) => {
    if (mode === 0) {
        configureStep.deleteSetTestKey(`${stepId}_${hiddenKey}`)
    } else if (mode === 1) {
        configureStep.deleteSetTestKey(`__${stepId}__${hiddenKey}`)
    } else if (mode === 2) {
        let existing_hidden
        if (!testState[stepId]) {
            existing_hidden = {};
        } else {
            existing_hidden = testState[stepId];
        }
        if (existing_hidden[`__${hiddenKey}`]) {
            delete existing_hidden[`__${hiddenKey}`]
        }
        configureStep.setTest({
            [stepId]: existing_hidden
        })
    }
}

export const retrieveHiddenStatePre = (testState,configureStep,stepId,mode) =>(hiddenKey, onFail = null) => {
    if (mode === 0) {
        return testState[`${stepId}_${hiddenKey}`];
    } else if (mode === 1) {
        return testState[`__${stepId}__${hiddenKey}`] ? testState[`__${stepId}__${hiddenKey}`] : onFail;
    } else if (mode === 2) {
        return (testState[stepId] && testState[stepId][`__${hiddenKey}`]) ? testState[stepId][`__${hiddenKey}`] : onFail;
    }
}

const useHiddenState = (testState,configureStep,stepId,mode) => {
    const embedHiddenState = embedHiddenStatePre(testState,configureStep,stepId,mode)
    const retrieveHiddenState = retrieveHiddenStatePre(testState,configureStep,stepId,mode)
    const deleteHiddenState = deleteHiddenStatePre(testState,configureStep,stepId,mode)
    return {retrieveHiddenState,embedHiddenState,deleteHiddenState}
    }
    
export default useHiddenState;