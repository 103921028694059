import React, { useState } from 'react';
import Atomics from "./atomicInputs";
import DescriptionBlock from "./description";
import Button from "@mui/material/Button";
import Editor from '@monaco-editor/react';

const scriptVarDataFn = (nodes, edges, currentlySelectedNode, scriptType) => {

    return [
        {
            label: 'Script:',
            varLabel: scriptType,
            type: "textInput",
            details: "Add an executable " + scriptType + "-script to this node.",
            display: (store, datum) => store[datum.varLabel],
        }
    ]
}

const ScriptEditor = ({ scriptType, userInputs, general, selectedNode, mutateStandardNodeData, currentlySelectedNode, configureSpecialProperties, nodes, edges, gptPrompts, ...other }) => {
    //Functions for modifying the standard fields
    const scriptVarData = scriptVarDataFn(nodes, edges, currentlySelectedNode, scriptType);
    const [script, setScript] = useState(general);
    const handleSettingChange = (arg) => (index, key, newData) => {
        const newScript = { ...script };
        const associatedDatum = scriptVarData.filter((datum) => datum.varLabel === arg)[0]
        if (associatedDatum.special) {
            configureSpecialProperties(selectedNode, arg, newData)
        }
        newScript[key] = newData;
        setScript(newScript);
        mutateStandardNodeData(selectedNode, "general", newScript)
    };
    return (
        <div>
            <DescriptionBlock title={scriptType+"-script executable"}>
                This an X script that runs before the main process phase.
                <br />
            </DescriptionBlock>
            <div style={{ marginTop: "15px" }}>
                {/* <div style={{ backgroundColor: "#1a1f22", padding: "10px" }}>
                    {scriptVarData.map((datum) => <Atomics
                        handleChange={handleSettingChange(datum.varLabel)}
                        datum={datum}
                        type={datum.type}
                        store={script}
                        idx={0}
                    />)}
                </div> */}
                <Editor height="90vh" defaultLanguage="python" defaultValue={script[scriptType]} onChange={(str)=>{handleSettingChange(scriptType)(1,scriptType,str)}} />
            </div>
        </div>
    );
};

export default ScriptEditor;
