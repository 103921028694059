import React from 'react';
import { ListItemButton} from '@mui/material';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';


const AutomataGenericButtons = ({buttonOpts, stepOperations }) => {
    return (
        <>
            {buttonOpts.map((buttonData, index) => {
                return (
                    <ListItemButton
                        key={index}
                        onClick={() => { buttonData.click(stepOperations) }}
                        disabled={buttonData?.disabled ? buttonData.disabled(stepOperations) : false}>
                        <ListItemAvatar>
                            <Avatar>
                                {buttonData.icon}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={buttonData.label}
                            secondary={buttonData.sublabel}
                        />
                    </ListItemButton>)
            })}
        </>

    );
};


export default AutomataGenericButtons;