import React from 'react';
import {MenuItem, Select } from '@mui/material';

const LexDynamicSelectMenu = ({ options, optionSelecton, selectStyle, menuItemStyle, handleSelectChange }) => {
    const selectedIndex = options.findIndex(obj => obj.name === optionSelecton.name);
    const handleChange = (event, newValue) => {
        handleSelectChange(options[event.target.value]);
    };
    return (<Select
        style={selectStyle}
        value={selectedIndex}
        onChange={handleChange}
        displayEmpty
        variant="standard"
        inputProps={{ "aria-label": "responsive select example" }}
    >
        {options.map((option, index) => (
            <MenuItem
                key={"lex_dynamic_selectMenu"+index}
                style={menuItemStyle}
                value={index}>
                {option.label}
            </MenuItem>))}
    </Select>
    );
};

export default LexDynamicSelectMenu;