import React, { useState, useContext } from 'react';
import APIContext from './context';
import { axiosWrapper } from '../../auth';
import { useErrorModal } from '../error';
import { Modal, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const APIContextProvider = ({ children }) => {
    const [error, setError] = useState(null);
    const [responseLoading, setResponseLoading] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const [dialogResponseActions, setDialogResponseActions] = useState({});
    const showError = useErrorModal();

    const completeDialogResponse = () => {
        setDialogResponseActions({});
    };

    const APIAxiosWrapper = async (url, data = {}, options = { headers: {} }) => {
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            if (e.response?.data?.errorFormatted) {
                showError(e.response.data.message, e.response.data.code);
            }
            setError(e);
            throw e;
        }
    };

    const APIAxiosWrapperWithLoading = async (url, data = {}, options = { headers: {} }) => {
        setResponseLoading(true);
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            if (e.response?.data?.errorFormatted) {
                showError(e.response.data.message, e.response.data.code);
            }
            setError(e);
            throw e;
        } finally {
            setResponseLoading(false);
        }
    };

    const APIAxiosWrapperWithDialog = (confirmationText, title, followUp = {}, url, data = {}, options = { headers: {} }) => {
        setDialogData({
            confirmationText,
            title,
            url,
            data,
            followUp,
            options,
        });
        setDialogOpen(true);
    };

    const executeDialogRequest = async () => {
        const { url, data, options, followUp } = dialogData;
        try {
            if (followUp.useLoading) {
                setResponseLoading(true);
            }
            let response = null;
            if (!followUp.mock) {
                response = await axiosWrapper(url, data, options);
            }
            setDialogResponseActions({ ...followUp, response });
            if (followUp.useLoading) {
                setResponseLoading(false);
            }
            setDialogOpen(false);
        } catch (e) {
            setDialogOpen(false);
            if (e.response?.data?.errorFormatted) {
                showError(e.response.data.message, e.response.data.code);
            }
            setError(e);
            throw e;
        }
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const APIAxiosWrapperFiles = async (url, data = {}, options = { headers: {} }) => {
        try {
            const response = await axiosWrapper(url, data, options);
            return response;
        } catch (e) {
            setError(e);
            throw e;
        }
    };

    return (
        <APIContext.Provider
            value={{
                api: APIAxiosWrapper,
                apiWithLoading: APIAxiosWrapperWithLoading,
                apiFileUpload: APIAxiosWrapperFiles,
                apiWithDialog: APIAxiosWrapperWithDialog,
                completeDialogResponse,
                dialogResponseActions,
            }}
        >
            {/* Dialog for confirmation */}
            <Dialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">{dialogData.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {dialogData.confirmationText}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleCloseDialog}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            executeDialogRequest().catch((e) => {
                                console.error(e);
                            });
                        }}
                        autoFocus
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Loading indicator */}
            <Modal
                open={responseLoading}
                aria-labelledby="loading-modal-title"
                aria-describedby="loading-modal-description"
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </Box>
            </Modal>

            {children}
        </APIContext.Provider>
    );
};

export default APIContextProvider;
