import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const GenerativeRAGCollector = ({ configureStep, stepData,stepId,macrosObject,prevState,testState, availableVariables, errorDisplay }) => {
    return <Collector
        stepId={stepId}
        descriptionText={"Please write your LLM prompt and assign an output variable. You may use any text variables previously set at a preceeding step."}
        inputFields={[
            quickInput(stepData, configureStep, "File Store Variable", "fileVarName", "Any files queries will be stored in this variable."),
            quickInput(stepData, configureStep, "LLM Prompt", "prompt", "This is the prompt/command that will be given to the LLM", true, 8, true, { ...macrosObject, ...prevState }, availableVariables)
        ]}
    />
};

export default GenerativeRAGCollector;