import React, { useState } from 'react';
import { Typography, Checkbox, FormControlLabel, Button } from '@mui/material';

const CheckboxCollector = ({ paramName,
  checkboxLabel,
  nextStep,
  configureStep, stepData, errorDisplay }) => {
  const [varName, setVarName] = useState(stepData?.[paramName] || false);

  const handleVarNameChange = (event) => {
    setVarName(event.target.checked);
    configureStep.set({
      [paramName]: event.target.checked,
    });
  };
  return (
    <div>
      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={varName}
              onChange={handleVarNameChange}
              name="paramName"
              color="primary"
            />
          }
          label={checkboxLabel}
        />
      </div>
      <div style={{ paddingTop: "10px" }}>
        <Button variant="contained" onClick={(e) => {
          configureStep.set({
            display: nextStep,
          })
        }}>Continue?</Button>
      </div>
    </div>
  );
};

export default CheckboxCollector;