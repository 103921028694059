import { useState,useEffect} from "react";

export const useWorkflowTriggers=()=>{
    const [workflowVersion, setWorkflowVersion] = useState(1)
    const [saveWorkflowVer, setSaveWorkflowVer] = useState(1);
    const [loadRefreshWorkflow, setRefreshWorkflow] = useState(1);
    const refresh = (timeout = 0) => {
        if (timeout) {
            setTimeout(() => {
                setRefreshWorkflow(v => v + 1)
            }, timeout)
        } else {
            setRefreshWorkflow(v => v + 1)
        }
    }
    const saveWorkflow = (timeout=0) => {
        if (timeout) {
            setTimeout(() => {
                setSaveWorkflowVer(v => v + 1)
            }, timeout)
        } else {
            setSaveWorkflowVer(v => v + 1)
        }
    }
    return {workflowVersion,saveWorkflowVer,setWorkflowVersion, loadRefreshWorkflow, setRefreshWorkflow,setSaveWorkflowVer,refresh,saveWorkflow}

}