import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, Box, CircularProgress, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import mammoth from 'mammoth';
import { Document, Page, pdfjs } from 'react-pdf';
import DownloadIcon from '@mui/icons-material/Download';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { PolygonLabel } from '../../Design/Polygons';

pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.min.mjs';

function highlightTextInHtml(htmlString, searchText) {
  if (!searchText) return htmlString;
  const regex = new RegExp(`(${searchText})`, 'gi');
  return htmlString.replace(regex, '<span style="background-color: yellow;">$1</span>');
}


const LexDocumentDisplay = ({ open, onClose, link, pageNumber, highlightText, documentName, documentAuthor }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const contentRef = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageSet, setPageSet] = useState(false); // Spinner depends on this
  const [v, setV] = useState(1);
  const [init, setInit] = useState(false);
  const [docxHtml, setDocxHtml] = useState(null);
  const [loadingDocx, setLoadingDocx] = useState(false);
  const pageRefs = useRef([]); // Array of refs for all pages
  const fileExtension = 'pdf';
  const [pageWidth, setPageWidth] = useState(null);
  const pageStyles = {
    filter: isDarkMode ? 'brightness(0.58) invert(1) hue-rotate(180deg)' : 'white',
  };
  useEffect(() => {
    const updatePageWidth = () => {
      if (contentRef.current) {
        const width = contentRef.current.offsetWidth;
        setPageWidth(width);
      }
    };

    updatePageWidth();

    window.addEventListener('resize', updatePageWidth);
    return () => {
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(Math.min(300, numPages));
    if (init === false) {
      setInit(true);
      if (contentRef.current) {
        const width = contentRef.current.offsetWidth;
        setPageWidth(width);
      }
    }
    setTimeout(() => {
      setV((v) => v + 1);
    }, 1000);
  };

  useEffect(() => {
    if (fileExtension === 'docx' && open) {
      setLoadingDocx(true);
      fetch(link)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
            .then((result) => {
              let html = result.value;
              html = highlightTextInHtml(html, highlightText);
              setDocxHtml(html);
              setLoadingDocx(false);
            })
            .catch((error) => {
              console.error('Error converting DOCX to HTML:', error);
              setLoadingDocx(false);
            });
        })
        .catch((error) => {
          console.error('Error fetching DOCX file:', error);
          setLoadingDocx(false);
        });
    }
  }, [link, open, highlightText]);

  // Scroll to the page passed as a prop
  useEffect(() => {
    if (pageRefs.current[pageNumber - 1]) {
      setPageSet(true); // When page is set, spinner can hide
      pageRefs.current[pageNumber - 1].scrollIntoView({ behavior: 'smooth' });
    }
  }, [numPages, pageNumber, v]);

  const handleOpen = () => {
    if (link) {
      let openUrl = link;

      // Append #page=number if pageNumber is specified
      if (pageNumber && pageNumber > 0) {
        openUrl = `${link}#page=${pageNumber}`;
      }

      window.open(openUrl, '_blank');
    }
  };
  const downloadBlobURL = (href, filename) => {
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

  };
  const handleDownload = () => {
    if (link) {
      downloadBlobURL(link, 'document.pdf');
    }
  };
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ padding: '0px', fontWeight: 'normal' }}>
        <PolygonLabel
          clip="polygon(0 0, calc(100% - 15px) 0, 100%  100%, 0 100%)"
          width='var(--right-poly-width)'
          sx={{ bgcolor: 'flags.right', color: "flags.rightText" }}
          style={{ width: 'fit-content', paddingRight: '2em' }}>
          <PolygonLabel
            clip="polygon(0 0, calc(100% - 15px) 0, 100%  100%, 0 100%)"
            height='60px'
            width='var(--left-poly-width)'
            className="formFieldPolygonLabel"
            sx={{ bgcolor: 'flags.middle', color: "backgroundSecondary.contrastText" }}
            style={{ width: 'fit-content', paddingRight: '2em' }}>
            <PolygonLabel className='deleteFormFieldButton'
              clip="polygon(0 0, calc(100% - 15px) 0, 100% 100%, 0 100%)"
              height='60px'
              width='var(--left-poly-width)'
              sx={{ bgcolor: 'flags.left', color: "flags.leftText" }}
              style={{ width: 'fit-content', paddingRight: '2em', paddingLeft: '14px' }}>
              Document Viewer
            </PolygonLabel>
            <span style={{ paddingLeft: '14px' }}>
              Title: {documentName}
            </span>
          </PolygonLabel>
          <span style={{ paddingLeft: '14px' }}>
            Author: {documentAuthor}
          </span>
        </PolygonLabel>
        <IconButton
          aria-label="open"
          onClick={handleOpen}
          style={{
            position: 'absolute',
            right: 88, // Adjust as needed
            top: 8,
          }}>
          <OpenInNewIcon />
        </IconButton>
        <IconButton
          aria-label="download"
          onClick={handleDownload}
          style={{
            position: 'absolute',
            right: 48,
            top: 8,
          }}
        >
          <DownloadIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent ref={contentRef} dividers style={{ height: '80vh', overflowY: 'auto' }}>
        {fileExtension === 'pdf' ? (
          <>
            {!pageSet && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}
            <Document
              key={link + init}
              file={link}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <div
                  key={`page_${index + 1}`}
                  ref={(el) => (pageRefs.current[index] = el)}
                  style={{ position: 'relative', ...pageStyles }}
                >
                  {pageWidth && (<Page
                    pageNumber={index + 1}
                    renderTextLayer={false} // Disable the text layer
                    width={pageWidth - 90}
                    renderAnnotationLayer={false} // (Optional) Disable annotations
                  />)}
                </div>
              ))}
            </Document>
          </>
        ) : fileExtension === 'docx' ? (
          loadingDocx ? (
            <Typography>Loading document...</Typography>
          ) : docxHtml ? (
            <div dangerouslySetInnerHTML={{ __html: docxHtml }} />
          ) : (
            <Typography>Error loading document.</Typography>
          )
        ) : (
          <Typography>Unsupported file type.</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LexDocumentDisplay;