import DocGenUploadCollector from '../../../components/Automata/DocumentGenerate/DocGenUploadCollector';
import DocGenDownload from '../../../components/Automata/DocumentGenerate/DocGenDownload';
import TextSnippetSharpIcon from '@mui/icons-material/TextSnippetSharp';
import LexTypography from '../../../components/Lex/LexTypography';
import { Box } from '@mui/material';

export const documentGenerateStep = {
    stepMenuOptions: ['primaryOptions','stepMetaData','testDataDisplay'],
    initializedStep:'primaryOptions',
    execution: (props)=>
        <Box sx={{padding:2,border:1, borderRadius:3, borderColor:'action.disabled',marginTop:3}}>
        <LexTypography  sx={{paddingBottom:2}} variant="h6">Download Document</LexTypography>
        <DocGenDownload {...props} />
        </Box>,
    executionSingleStepText: '',
    continueCondition: ({stepData,testState}) => true,
    overview: {
        name: 'documentGenerateStep',
        label: 'Generate a Word document',
        sublabel: 'You may insert any generated text into a Word document template of your choosing.',
        icon: <TextSnippetSharpIcon />,
        click: ({ configureStep, stepData, manager, errorDisplay }) => {
            configureStep.set({
                name: 'documentGenerateStep',
                labelDisplay: "Template",
                collectedFields: [],
                display: 'primaryOptions',
                initialized: true
            })
        }
    },
    testDataDisplay: {
        label: 'Test Output',
        enabled: true,
        header: 'Download your completed document',
        useExpandableDescription: true,
        description: '',
        collectorComponent: (props) => <DocGenDownload {...props} />

    },
    primaryOptions: {
        label: 'Step Configuration',
        header: 'Upload your template',
        useExpandableDescription: true,
        description: 'Please upload a document template.',
        collectorComponent: (props) => <DocGenUploadCollector {...props} />,
        buttonOpts: [],
        inputOpts: [],
    },
    default: {

    }


};