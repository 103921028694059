import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import DoDisturbOnOutlinedIcon from '@mui/icons-material/DoDisturbOnOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import { MarkdownTable } from '../../../Lex/LexMarkdownTable';
import { Box, Tooltip, IconButton, } from '@mui/material';
import { useModalOutputEditorArraySingle } from '../../Common/hooks/useModalOutputEditorArraySingle';
import EditModal from '../../../Modals/EditModal';

const FlexTempAccordion = ({ details, summary, index, configureStep, testState, stepData, stepId }) => {
  const {
    submitModalEdits,
    editText,
    handleSelectOutput,
    selectedOutput,
    editModalData,
    closeModal,
    outputs
  } = useModalOutputEditorArraySingle(configureStep, testState, stepId, stepData?.varName, index);
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  return (
    <div >
      <EditModal
        enabled={editModalData.enabled}
        varText={editModalData.varText}
        closeModal={closeModal}
        submitEdits={submitModalEdits}
        variableName={editModalData.varName} />
      <Accordion
        onChange={(event, isExpanded) => setDescriptionExpanded(isExpanded)}
        sx={{ boxShadow: 0, bgcolor: "background.paper" }}
        expanded={descriptionExpanded}>
        <AccordionSummary
          expandIcon={
            <Tooltip title="Toggle Description">
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  setDescriptionExpanded((prev) => !prev);
                }}>
                {descriptionExpanded ? (
                  <DoDisturbOnOutlinedIcon fontSize="small" />
                ) : (
                  <HelpOutlineOutlinedIcon fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
          }>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography sx={{ paddingLeft: 1 }} variant="h6">
              {summary}
            </Typography>
            <Box>
              {descriptionExpanded && (
                <Tooltip title="Edit Output">
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      editText(stepData?.varName, stepId);
                    }}>
                    <SettingsIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownTable height={'auto'} markdown={details ? details : ""} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
const GenerativeSeqTestDisplay = ({ fixHeight, ...otherProps }) => {
  const messages = otherProps.testState?.[otherProps.stepData?.varName] || [];
  return (
    <Box sx={{ height: fixHeight ? fixHeight : null, overflowY: fixHeight ? "scroll" : null }}>
      {messages.map((message, index) => (
        <FlexTempAccordion details={message} index={index} summary={(otherProps.stepData.enumLabel ? otherProps.stepData.enumLabel : "Item") + ' ' + (index + 1)} {...otherProps} />
      ))}
    </Box>
  );
};

export default GenerativeSeqTestDisplay;