
import React from 'react';
import { MarkdownTable } from '../../../Lex/LexMarkdownTable';
import { Box } from '@mui/material';
import EditModal from '../../../Modals/EditModal';
import { useModalOutputEditorSingle } from '../../Common/hooks/useModalOutputEditorSingle';
import OutputDisplaySingle from '../../Common/OutputDisplaySingle';
import OutputSideMenu from '../../Common/OutputSideMenu';

const GenerativeStepTestDisplay = ({ fixHeight, configureStep, stepData, errorDisplay, testState, stepId }) => {
    const {
        submitModalEdits,
        editText,
        handleSelectOutput,
        selectedOutput,
        editModalData,
        outputs,
        closeModal
    } = useModalOutputEditorSingle(configureStep, testState, stepId, stepData?.varName);
    let configuredOutputs = outputs.map((output) => ({
        mode: output.key,
        text: output.label
    }))
    console.log(configuredOutputs)
    configuredOutputs = configuredOutputs.filter(output => output.text !== "History")
    return (
        <Box display="flex">
            <EditModal
                enabled={editModalData.enabled}
                varText={editModalData.varText}
                submitEdits={submitModalEdits}
                closeModal={closeModal}
                variableName={editModalData.varName} />
            {stepData?.useMultiParse ? <OutputSideMenu
                mode={selectedOutput}
                setMode={handleSelectOutput}
                options={configuredOutputs} /> : null}
            <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
                {stepData?.useMultiParse ? (
                    <MarkdownTable markdown={outputs.find(output => output.key === selectedOutput)?.value || ""} />

                ) : <OutputDisplaySingle
                    fixHeight
                    editText={editText}
                    markdown={testState?.[stepData?.varName] || ""}
                    varName={stepData?.varName}
                    stepId={stepId} />
                }
            </Box>
        </Box>
    );
};

export default GenerativeStepTestDisplay;