import { useContext } from 'react';
import DesContext from '../../../../contexts/singleFlow/context';
import LexButton from '../../../Lex/LexButton';
import { Box } from '@mui/material';

const DocGenDownload = ({ configureStep, stepData, errorDisplay, stepIndex, stepId }) => {
    let { manager } = useContext(DesContext);
    return (
        <Box>
            <LexButton
                variant="contained"
                color="primary"
                onClick={manager.downloadCompletedTemplate({ stepData, stepIndex, stepId })}>
                Download
            </LexButton>
        </Box>)
}

export default DocGenDownload;