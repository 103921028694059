import React, { useContext, useEffect } from 'react';
import APIContext from './context';

export const useAPIDialog = (text, title, url, data, followUp, context, job, useLoading = false) => {
    const { dialogResponseActions, completeDialogResponse, apiWithDialog } = useContext(APIContext);

    useEffect(() => {
        if (dialogResponseActions.context === context && dialogResponseActions.job === job) {
            followUp(dialogResponseActions.response,completeDialogResponse);
        }
    }, [dialogResponseActions, context, job]);

    const fn = () => {
        apiWithDialog(
            text,
            title,
            { context, job, useLoading },
            url,
            data
        );
    };

    return fn;
};


export const useMockDialog = (text, title, followUp, context, job, useLoading = false) => {
    const { dialogResponseActions, completeDialogResponse, apiWithDialog } = useContext(APIContext);

    useEffect(() => {
        if (dialogResponseActions.context === context && dialogResponseActions.job === job) {
            followUp(...dialogResponseActions.fnArgs,completeDialogResponse);
        }
    }, [dialogResponseActions, context, job]);

    const fn = (...fnArgs) => {
        apiWithDialog(
            text,
            title,
            { context, job, useLoading, mock: true, fnArgs },
            '',
            ''
        );
    };

    return fn;
};

