import Collector from '../../Common/Collector';
import { quickCheck, quickInput, quickTestInput } from '../../Common/utilities';

const IDIStepCollector = ({ configureStep, stepData, errorDisplay, stepId, testState,prevState,macrosObject, availableVariables, ...other }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput(stepData, configureStep, "Target Website", "targetURL", "Please provide the full URL for the target website.", false, 1),
            quickInput(stepData, configureStep, "Output Variable", "varName", "This is the variable the output will be stored in.", false, 1),
            quickInput(stepData, configureStep, "Search Query", "prompt", "This is the query that will be sent to external search engine.", true, 8, true, { ...macrosObject, ...prevState }, availableVariables),
            quickInput(stepData, configureStep, "Instructions", "instructions", "Please provide instructions on how to browse the external website.", true, 8, true, { ...macrosObject, ...prevState }, availableVariables)
        ]}
    />
};

export default IDIStepCollector;