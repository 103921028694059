import React, { useState } from 'react';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';

const LexSimpleSelect = ({options,handleChange,value,title}) => {
    return (
        <FormControl fullWidth variant="outlined" style={{ marginTop: '1rem' }}>
            <InputLabel id="model-select-label">{title}</InputLabel>
            <Select
                labelId="model-select-label"
                id="model-select"
                value={value || "Loading..."}
                onChange={handleChange}
                label="Select Model"
            >
                {options.map((option,index) => {
                    return <MenuItem key={option+index+title+"k1"} value={option}>{option}</MenuItem>
                })}
            </Select>
        </FormControl>
    );
};

export default LexSimpleSelect;