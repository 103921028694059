import React from "react";
import { Box } from "@mui/material";

const SidebarInternalWrapper = ({children, ...props}) => {
    return (
        <Box 
        sx={{paddingLeft:2,paddingTop:3,paddingRight:2}}
        style={{
            overflowY: 'scroll',
            scrollbarGutter: 'stable both-edges',
            ...props.style
        }}
        >
      {children}
        </Box>
    )
}

export default SidebarInternalWrapper;