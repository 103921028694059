import React, { useState} from 'react';
import useIsSmallContainer from '../../Common/hooks/useIsSmallContainer';
import LexDynamicTabMenu from '../../../Lex/LexDynamicTabMenu';
import LexDynamicSelectMenu from '../../../Lex/LexDynamicSelectMenu';
import { Box, useTheme } from '@mui/material';

const useDynamicMenu = (defaultOption,stepProfile,stepData) => {
  let displayOptions = stepProfile['stepMenuOptions'].map((option) => {
    if(stepProfile[option]?.show){
      if(!stepProfile[option].show(stepData)){
        return {name:false,label:false}
      }
    }
    if(option === 'stepMetaData'){
      return {
        name: option,
        label: "Metadata"
    
      }
    }
    return {
    name: option,
    label: stepProfile[option]?.label

  }});
  displayOptions = displayOptions.filter((option) => option.name !== false);
  const [selectedOption, setSelectedOption] = useState(displayOptions[0]);
  return { selectedOption, setSelectedOption,displayOptions };
}

const AutomataStepDynamicMenu = ({ options,stepId, selectedOption, setSelectedOption }) => {
  const { containerRef, isSmallContainer } = useIsSmallContainer();
  return (
    <Box
      ref={containerRef}
      sx={{borderBottom:1, marginBottom: "10px",borderTop:1,borderColor:"divider"}}
      style={{
        width: "100%"
      }}>
      {!isSmallContainer ? (
        <LexDynamicTabMenu
          keyData={stepId}
          tabOptions={options}
          tabSelected={selectedOption}
          handleTabChange={setSelectedOption}
          style={{ width: "100%" }}
        />
      ) : (
        <Box
          style={{
            border: "1px solid #ccc",
            padding: "15px",
          }}>
          <LexDynamicSelectMenu
            options={options}
            optionSelecton={selectedOption}
            selectStyle={{ width: "100%", fontSize: "1.2em"}}
            handleSelectChange={setSelectedOption}
          />

        </Box>
      )}
    </Box>
  );
};

export { AutomataStepDynamicMenu, useDynamicMenu };