import LexFormSelect from '../../../Lex/LexFormSelect';
import LexButton from '../../../Lex/LexButton';
import { Box } from '@mui/material';

const ContextCollectorSeq = ({ nextStep, configureStep, stepData, errorDisplay }) => {
    let testOpts = configureStep.priorSeqConversations()
    let testOptsAll = configureStep.priorSeqStepConversations()
    let testOptsIds = testOpts.map((x) => x[0])
    return (
        <Box>
            <LexFormSelect
                onChange={(e) => {
                    if (testOptsIds.includes(e.target.value)) {
                        configureStep.set({
                            sequencePrior: true,
                            contextToContinue: e.target.value
                        })
                    } else {
                        configureStep.set({
                            sequencePrior: false,
                            contextToContinue: e.target.value
                        })
                    }

                }}
                selectValue={stepData?.contextToContinue ? stepData?.contextToContinue : null}
                inputLabel={"Existing Conversation"}
                menuText={"Select An Existing Conversation"}
                selectionOptions={testOptsAll}
                formatMenuItem={([selStr, selIdx]) => "Step " + (1 + selIdx)}

            />
            <Box style={{ paddingTop: "10px" }}>
                <LexButton
                    variant="outlined"
                    onClick={(e) => { configureStep.set({ display: nextStep }); }}>
                    Continue?
                </LexButton>
            </Box>
        </Box>)
};


export default ContextCollectorSeq;