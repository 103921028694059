import React from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@mui/material';
import LexTypography from "../LexTypography";

const LexExpandableAccordion = ({ summary, details, Summary,...props }) => (
    <Accordion
        disableGutters={true}
        defaultExpanded={true}
        sx={{
            borderTop:1,
            borderBottom:1,
            boxShadow:0,
            bgcolor:'background.sidebar',
            marginTop:1,
            borderColor:'action.disabled',
            borderRadius:0
        }}
        style={{
            height: '100%',
            width: '100%',
        }}{...props}>
        <Summary />
        <AccordionDetails style={{ padding: "0px" }}>
            {details}
        </AccordionDetails>
    </Accordion>
);

export default LexExpandableAccordion;