import Collector from '../../Common/Collector';
import { quickInput } from '../../Common/utilities';

const GenerativeStepVerificationCollector = ({ configureStep, stepData, errorDisplay, stepId, testState, ...other }) => {
    return <Collector
        stepId={stepId}
        inputFields={[
            quickInput(
                stepData, configureStep, "Validation Criteria", "verification_critera", "This is the prompt/command that will be used to evaluate the results of multiparse.", true, 8, true, testState)
        ]}
    />
};

export default GenerativeStepVerificationCollector;