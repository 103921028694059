import { formDataStep } from "./formData";
import { generativeStep } from "./generativeStep";
import { uploadStep } from "./upload";
import { apiStep } from "./api";
import { generativeSequence } from "./generativeSequence";
import { ragStep } from "./rag";
import { documentGenerateStep } from "./documentGenerate";
import {idiStep} from "./idi";

export const stepOptionsObject = {
    formDataStep: formDataStep,
    generativeStep: generativeStep,
    generativeSequence: generativeSequence,
    uploadStep: uploadStep,
    apiStep: apiStep,
    ragStep: ragStep,
    documentGenerateStep: documentGenerateStep,
    idiStep: idiStep,
    
};

export const stepOptionsArray = [
    formDataStep,
    generativeStep,
    generativeSequence,
    uploadStep,
    apiStep,
    ragStep,
    documentGenerateStep,
    idiStep
]